import { inject, Injectable } from '@angular/core';
import { P2pBuyingService } from '@core/p2p-services/p2p-buying.service';
import { P2pMarketService } from '@core/p2p-services/p2p-market.service';
import { P2PModalService } from '@core/p2p-services/p2p-modal.service';
import { BreakpointsTypes, IMarketplaceKitData, ModalNames, OrderStatusEnum } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { combineLatest, map, Observable } from 'rxjs';

import { LayoutState } from '@app/core/state/layout';
import { BehaviorSubjectItem } from '@app/shared/utils';

@Injectable()
export class P2pBasketService {
  readonly #p2pBuyingService = inject(P2pBuyingService);
  readonly #p2pMarketService = inject(P2pMarketService);
  readonly #p2pModalService = inject(P2PModalService);

  @Select(LayoutState.breakpoints)
  breakpoints$!: Observable<BreakpointsTypes | null>;

  nativeBreakpoint$: Observable<string> = this.breakpoints$.pipe(map((breakpoints) => breakpoints?.native || 'xs'));
  p2pPermissions$ = this.#p2pMarketService.p2pPermissions$;

  sortingSubject$ = new BehaviorSubjectItem(false);

  selectedPurchaseItems$: Observable<IMarketplaceKitData[]> = combineLatest([
    this.#p2pBuyingService.purchasing$.pipe(map((items) => items.filter((item) => item.status === OrderStatusEnum.NEW))),
    this.sortingSubject$.value$,
  ]).pipe(map(([items, sorting]) => this.sortingByPrice(items, sorting)));
  countSelectedItems$: Observable<number> = this.selectedPurchaseItems$.pipe(map((items) => items.length));
  summaryPrice$: Observable<number> = this.selectedPurchaseItems$.pipe(map((items) => items.reduce((acc, item) => acc + item.price, 0)));
  profit$: Observable<number | null> = this.selectedPurchaseItems$.pipe(
    map((items) => {
      const profit = items.reduce((acc, item) => acc + (item.recommendedPrice || 0) - item.price, 0);
      return profit > 0 ? profit : null;
    }),
  );

  unselectByIds(ids?: number[]): void {
    this.#p2pMarketService.unselectItems(ids);
  }

  changeSorting(sorting: boolean): void {
    this.sortingSubject$.value = sorting;
  }

  buyItems(ids: number[]): void {
    this.#p2pBuyingService.createBidByBatch(ids);
  }

  sortingByPrice(items: IMarketplaceKitData[], sorting: boolean): IMarketplaceKitData[] {
    return [...items].sort((a, b) => (sorting ? a.price - b.price : b.price - a.price));
  }

  openModal(modalName: ModalNames): void {
    this.#p2pModalService.openModal(modalName);
  }
}
