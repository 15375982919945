import { IGiftProvider } from '@dev-fast/types';

export const GIFT_PROVIDERS: IGiftProvider[] = [
  {
    name: 'KINGUIN',
    items: [
      { amount: 500, link: 'https://www.kinguin.net/category/200390/csmarket-gg-5-gems-gift-card?referrer=csmarket.gg&ec=1' },
      { amount: 1000, link: 'https://www.kinguin.net/category/200391/csmarket-gg-10-gems-gift-card?referrer=csmarket.gg&ec=1' },
      { amount: 2500, link: 'https://www.kinguin.net/category/200392/csmarket-gg-25-gems-gift-card?referrer=csmarket.gg&ec=1' },
      { amount: 5000, link: 'https://www.kinguin.net/category/200393/csmarket-gg-50-gems-gift-card?referrer=csmarket.gg&ec=1' },
      { amount: 10000, link: 'https://www.kinguin.net/category/200396/csmarket-gg-100-gems-gift-card?referrer=csmarket.gg&ec=1' },
      { amount: 20000, link: 'https://www.kinguin.net/category/200397/csmarket-gg-200-gems-gift-card?referrer=csmarket.gg&ec=1' },
      { amount: 50000, link: 'https://www.kinguin.net/category/200399/csmarket-gg-500-gems-gift-card?referrer=csmarket.gg&ec=1' },
      { amount: 100000, link: 'https://www.kinguin.net/category/200400/csmarket-gg-1000-gems-gift-card?referrer=csmarket.gg&ec=1' },
    ],
  },
];
