@if (config) {
  <div class="inventory__wrapper">
    <div class="inventory__participation-panel">
      <div class="participation-title">
        {{ 'LOCAL.INVENTORY.BET_PANEL.YOUR_BET' | translate }}
        <app-ui-increase
          [className]="'participation__total-price'"
          [valueType]="'currency'"
          [setCurValue]="totalPrice"
        >
        </app-ui-increase>
      </div>
      <button
        class="participation-button participation-button--confirm btn btn-primary"
        [disabled]="participationDisabled"
        (click)="onParticipate()"
      >
        <span class="participation-button-text">
          <span>{{ 'LOCAL.INVENTORY.BET_PANEL.PARTICIPATE' | translate }}</span>
          @if (config.name === 'classic') {
            <span class="participation-timer"
              >:&nbsp;
              @if (liteState) {
                <span>
                  <app-ui-countdown
                    [until]="liteState.timer"
                    [reset]="liteState.freeze"
                    [minutes]="0.92"
                  >
                  </app-ui-countdown>
                </span>
              }
            </span>
          }
        </span>
      </button>
    </div>
    <div class="inventory__info-panel">
      <div class="participation-description">
        @if (config.max_bet) {
          <div class="participation-description__title">
            {{ 'LOCAL.INVENTORY.BET_PANEL.BET' | translate }}
          </div>
          <div class="participation-description__price">
            {{ config.min_bet * 100 | appCurrency }} - {{ config.max_bet * 100 | appCurrency }}
          </div>
        } @else {
          <div class="participation-description__title">
            {{ 'LOCAL.INVENTORY.BET_PANEL.MIN_BET' | translate }}
          </div>
          <div class="participation-description__price">
            {{ config.min_bet * 100 | appCurrency }}
          </div>
        }
      </div>
      <div
        class="participation-description"
        [ngClass]="{
          success: selectedItems.length <= config.max_items_per_trade,
          alert: selectedItems.length > config.max_items_per_trade
        }"
      >
        {{ 'LOCAL.INVENTORY.BET_PANEL.ITEMS' | translate }} {{ selectedItems.length }} /
        {{ config.max_items_per_trade }}
      </div>
    </div>
    <div class="inventory__items-wrapper">
      <ng-scrollbar
        [autoHeightDisabled]="true"
        visibility="hover"
      >
        <div class="inventory__items-container">
          <div class="inventory__items-list">
            @if (participatedItems && participatedItems.length > 0) {
              @for (item of participatedItems; track trackingFunction($index, item)) {
                <app-ui-skin-item-new
                  [name]="item.name"
                  [icon]="item.icon"
                  [color]="item.color"
                  [price]="item.price"
                >
                </app-ui-skin-item-new>
              }
            }
            @if (participated && participated.items.length > 0) {
              @for (item of participated.items; track trackingFunction($index, item)) {
                <app-ui-skin-item-new
                  [name]="item.name"
                  [icon]="item.icon"
                  [color]="item.color"
                  [price]="item.price"
                  [delay]="participated.timeout * 1000"
                >
                </app-ui-skin-item-new>
              }
            }
            @if (selectedItems && selectedItems.length > 0) {
              @for (item of selectedItems; track trackingFunction($index, item)) {
                <app-ui-skin-item-new
                  [name]="item.name"
                  [icon]="item.icon"
                  [color]="item.color"
                  [id]="item.id"
                  [price]="item.price"
                  (skinClick)="clickOnSkinEmit.emit($event)"
                  class="selected"
                >
                </app-ui-skin-item-new>
              }
            }
          </div>
          <div class="participation-items-holder">
            @for (item of minItemsPerTrade; track item) {
              <div class="participation-items-holder_item participation-items-holder_item--required"></div>
            }
            @for (item of maxItemsPerTrade; track item) {
              <div class="participation-items-holder_item"></div>
            }
          </div>
        </div>
      </ng-scrollbar>
    </div>
    <div class="inventory__control-panel">
      <button
        class="btn btn-primary btn-semi-round w-f"
        [disabled]="clearDisabled"
        (click)="cleanParticipate()"
      >
        {{ 'LOCAL.INVENTORY.BET_PANEL.CLEAR' | translate }}
      </button>
    </div>
  </div>
}
