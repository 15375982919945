import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import type { IFormattedMarketplaceItem, IP2pDepositingItemV2 } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs';

import { CurrencyComponent } from '@app/core/currency';
import { ImageErrorHandlerDirective } from '@app/shared/directives/image-error-handler/image-error-handler.directive';
import { HashPipe, ReplaceAvatarHashPipeModule } from '@app/shared/pipes';
import { UserAvatarComponent } from '@app/ui/components/index';

import { TradeCompleteService } from '../../../../../p2p-trade-complete.service';

@Component({
  standalone: true,
  selector: 'app-trade-results-block',
  templateUrl: './trade-results.component.html',
  styleUrls: ['./trade-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TradeCompleteService],

  imports: [
    NgClass,
    TranslateModule,
    MatIconModule,
    HashPipe,
    ReplaceAvatarHashPipeModule,
    UserAvatarComponent,
    CurrencyComponent,
    ImageErrorHandlerDirective,
  ],
})
export class TradeResultsComponent {
  tradeOffer = input<IP2pDepositingItemV2>();

  #service = inject(TradeCompleteService);

  userAvatarHash = toSignal(this.#service.user$.pipe(map((value) => value?.avatar)));
  username = toSignal(this.#service.user$.pipe(map((value) => value?.name)));

  get buyerAvatarHash(): string {
    const tradeOffer = this.tradeOffer();
    if (!tradeOffer) {
      return '';
    }

    return tradeOffer.order.buyerSteamAvatar;
  }

  get buyerSteamName(): string {
    const tradeOffer = this.tradeOffer();
    if (!tradeOffer) {
      return '';
    }

    return tradeOffer.order.buyerSteamName;
  }

  getItemsOfferArray(items: IFormattedMarketplaceItem[] = []): IFormattedMarketplaceItem[] {
    if (!items) {
      return Array(4);
    }

    const itemsLength = items.length;

    switch (true) {
    case itemsLength < 4:
      return [...items, ...new Array(4 - itemsLength)];
    default:
      return items;
    }
  }
}
