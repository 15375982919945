import { SocialItem, SocialType } from '@dev-fast/types';

export const LOGIN_PROVIDERS: SocialItem[] = [
  { name: SocialType.Steam, title: 'Steam' },
  // { name: SocialType.MetaMask, title: 'MetaMask' },
  // { name: SocialType.Google, title: 'Google' },
  // { name: SocialType.Vkontakte, title: 'VK' },
  // { name: SocialType.Twitter, title: 'Twitter' },
  // { name: SocialType.Facebook, title: 'Facebook' },
  // { name: SocialType.Yandex, title: 'Yandex' },
  // { name: SocialType.Twitch, title: 'Twitch' },
  // { name: SocialType.Telegram, title: 'Telegram' },
];
export const SOCIALS_MEDIA: SocialItem[] = [
  { name: SocialType.Facebook, svgIcon: 'facebook', link: 'https://www.facebook.com/groups/359528683246766' },
  // { name: SocialType.Youtube, svgIcon: 'youtube-btn', link: '' },
  { name: SocialType.Discord, svgIcon: 'discord', link: 'https://discord.gg/KRDaFcnaWw' },
  // { name: SocialType.Twitter, svgIcon: 'socials-x', link: '' },
  { name: SocialType.Vkontakte, svgIcon: 'vk-bold', link: 'https://vk.com/csmarketgg' },
  // { name: SocialType.Instagram, svgIcon: 'instagram-line', link: '' },
  { name: SocialType.Steam, svgIcon: 'steam-bold', link: 'https://steamcommunity.com/groups/csmarketgg' },
];
