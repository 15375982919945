import { Injectable } from '@angular/core';
import { INotification, NewMenu, PROJECT } from '@dev-fast/types';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { NotificationsService as CoreNotiService } from '@app/core/notification-service';
import { CloseMenu, OpenMenu } from '@app/core/state/layout';
import { NotificationsEngine } from '@app/widgets/notifications-new';

@Injectable()
export class NotificationsService implements NotificationsEngine {
  project = PROJECT.MARKET;
  notifications$: Observable<INotification[]>;
  countNewNotifications$: Observable<number>;

  constructor(private readonly notificationsService: CoreNotiService) {
    this.notifications$ = this.notificationsService.notifications$;
    this.countNewNotifications$ = this.notificationsService.countNewNotifications$;
  }

  removeNotification(id: number): void {
    this.notificationsService.removeNotification(id);
  }
  watchAllNotifications(): void {
    this.notificationsService.watchAllNotifications();
  }
  watchNotification(id: number): void {
    this.notificationsService.watchNotification(id);
  }
  @Dispatch() closeWidget = (): CloseMenu => new CloseMenu(NewMenu.NOTIFICATIONS);
  @Dispatch() openWidget = (): OpenMenu => new OpenMenu(NewMenu.NOTIFICATIONS);
}
