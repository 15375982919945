import { InjectionToken } from '@angular/core';
import { IFilterFormContent, IInventoryShortInfo, ILocales, IUserDetailed, MarketSortingTypes, TransactionWay } from '@dev-fast/types';
import { Observable } from 'rxjs';

export const HEADER_ENGINE = new InjectionToken<HeaderEngine>('HEADER_ENGINE');

export interface HeaderEngine {
  readonly locales$: Observable<ILocales[]>;
  readonly currentLocale$: Observable<ILocales>;
  readonly user$: Observable<IUserDetailed | null | undefined>;
  readonly canLevelUp$: Observable<boolean>;
  readonly userBalance$: Observable<number>;
  readonly inventoryShortInfo$: Observable<IInventoryShortInfo>;
  readonly marketFilters$: Observable<IFilterFormContent<MarketSortingTypes>>;
  readonly isMobile$: Observable<boolean>;

  login(): void;

  openTransactions(way: TransactionWay): void;

  openInventory(): void;

  changeMarketFilters(filter: Record<string, string>): void;
}
