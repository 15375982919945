<app-ui-button
  class="open-btn"
  [disabled]="categoriesMenuOpened()"
  (buttonClick)="toggleCategoriesMenu()"
>
  @if (categoriesMenuOpened()) {
    <mat-icon
      icon-left
      svgIcon="close"
      class="tw-text-gray-900"
    />
  } @else {
    <mat-icon
      icon-left
      svgIcon="list"
      class="tw-text-gray-900"
    />
  }

  <span class="max-md:tw-hidden md:tw-ml-2">
    {{ 'P2P_WIDGETS.CATEGORY' | translate }}
  </span>
</app-ui-button>
@defer (when categoriesMenuOpened()) {
  @if (categoriesMenuOpened()) {
    <app-categories-dropdown
      class="tw-absolute tw-left-0 tw-w-full tw-h-dvh tw-bg-gray-700 tw-z-50 tw-rounded-lg"
      [(categoriesMenuOpened)]="categoriesMenuOpened"
      [items]="items()"
    />
  }
}
