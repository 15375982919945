@if (
  {
    panels: panels$ | async
  };
  as data
) {
  <div class="action-menu">
    <button
      class="btn btn-md sq btn-round btn-transparent-dark--design action-menu__button"
      [class.tw-bg-gray-700]="isMarket()"
      (click)="openMenu(MenuEnum.MAIN_MENU)"
    >
      @if (user) {
        <app-ui-user-avatar
          [url]="user.avatar"
          [type]="user.profileType"
          [noBorder]="hideAvatarBorder()"
          [noChevron]="hideAvatarChevron()"
        ></app-ui-user-avatar>
      } @else {
        <mat-icon svgIcon="settings-fill--design"></mat-icon>
      }
      <ng-content></ng-content>
    </button>
    @if (activeMenu$ | async; as activeMenu) {
      @if (isMenuOpened(activeMenu)) {
        <div
          class="active-panel__wrapper"
          inViewport
          [inViewportOptions]="{ threshold: [0] }"
          @replacementScaleAnimation
        >
          <div class="active-panel__container">
            @if (activeMenu.includes(MenuEnum.MAIN_MENU)) {
              <app-menu-panel
                class="menu-panel"
                [activeMenu]="activeMenu"
                [userDetailed]="user"
                [inventoryShortInfo]="inventoryShortInfo$ | myAsync"
                [shopParams$]="shopParams$"
                [lockedPanels$]="lockedPanels$"
                [locales]="locales"
                [locale]="locale"
                [soundStatus]="soundStatus$ | myAsync"
                [canLevelUp]="canLevelUp"
                [userBalance]="userBalance"
                (languageChange)="onLangChange($event)"
                (openMenuEvent)="openMenu($event)"
                (closeMenuEvent)="closeMenu($event)"
                (onauthClick)="onAuthClick(user)"
                (soundToggle)="onSoundToggle()"
                (clickBack)="closeMenu(MenuEnum.MAIN_MENU)"
              >
              </app-menu-panel>
            }
            @if (activeMenu.includes(MenuEnum.LANGUAGE)) {
              <app-language-panel
                class="language-panel"
                [locales]="locales"
                [locale]="locale"
                (languageChange)="onLangChange($event)"
                (closePanel)="closeMenu($event)"
              >
              </app-language-panel>
            }
          </div>
        </div>
        <div
          class="background-panel__wrapper"
          (click)="closeAllMenu()"
          @replacementAnimation
        ></div>
      }
    }
  </div>
}
