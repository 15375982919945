<div
  [ngClass]="{ 'right-sidebar-btns_is-panel-opened': isOpenedRightPanel }"
  class="right-sidebar-btns right-sidebar-btns_desktop"
>
  @if (hiddenScrollBtn) {
    <div
      class="right-sidebar-btns__btn"
      (click)="onClickScrollTop()"
    >
      <mat-icon
        class="right-sidebar-btns__btn_go-up"
        svgIcon="point-arrow--design"
      ></mat-icon>
    </div>
  }

  <button
    [disabled]="disableTradeBtn"
    class="right-sidebar-btns__btn right-sidebar-btns__btn_market"
    (click)="onClickTradeBtn()"
  >
    <mat-icon svgIcon="shopping-cart-design"></mat-icon>
  </button>
  <div
    class="right-sidebar-btns__btn right-sidebar-btns__btn_tech-support"
    (click)="onClickOpenChatra()"
  >
    <mat-icon svgIcon="support-agent-design"></mat-icon>
  </div>
</div>
<div
  class="right-sidebar-btns right-sidebar-btns_mobile"
  [class.is-opened]="isMobileMenuOpened()"
  [@sidebarAnimation]="isMobileMenuOpened()"
>
  @if (isMobileMenuOpened()) {
    @if (hiddenScrollBtn) {
      <div
        class="right-sidebar-btns__btn"
        (click)="onClickScrollTop()"
      >
        <mat-icon
          class="right-sidebar-btns__btn_go-up"
          svgIcon="point-arrow--design"
        ></mat-icon>
      </div>
    }
    <button
      [disabled]="disableTradeBtn"
      class="right-sidebar-btns__btn right-sidebar-btns__btn_market"
      (click)="onClickTradeBtn()"
    >
      <mat-icon svgIcon="shopping-cart-design"></mat-icon>
    </button>
    <div
      class="right-sidebar-btns__btn right-sidebar-btns__btn_tech-support"
      (click)="onClickOpenChatra()"
    >
      <mat-icon svgIcon="support-agent-design"></mat-icon>
    </div>
  }
  <div
    class="right-sidebar-btns__btn"
    (click)="onToggleMobileMenu()"
  >
    <mat-icon [svgIcon]="isMobileMenuOpened() ? 'close' : 'menu-dots--design'"> </mat-icon>
  </div>
</div>
