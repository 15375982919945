import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, input, model, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { RepeatPipe } from 'ngxtension/repeat-pipe';

import { ClickOutsideModule } from '@app/shared/directives';

import { HEADER_ENGINE, HeaderEngine } from '../../symbols';
import { VALUE_EXCEPTIONS } from '../category-menu.const';

export interface CategoriesFilter {
  label: string;
  value: string;
  key?: string;
  icon?: string;
  items?: CategoriesFilter[];
}

@Component({
  selector: 'app-categories-dropdown',
  standalone: true,
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
  imports: [RepeatPipe, NgClass, TranslateModule, ClickOutsideModule, MatIconModule, NgTemplateOutlet],
})
export class CategoriesDropdownComponent {
  readonly #headerEngine: HeaderEngine = inject(HEADER_ENGINE);

  items = input<CategoriesFilter[] | null>(null);
  categoriesMenuOpened = model<boolean>(false);

  activeIndex = signal<number>(-1);
  activeIndexByMobile = computed(() => {
    const isMobile = this.isMobile();
    const activeIndex = this.activeIndex();
    if (activeIndex === -1 && !isMobile) {
      return 0;
    }
    return activeIndex;
  });
  activeMenu = computed<CategoriesFilter | null>(() => {
    const items = this.items();
    return items ? items[this.activeIndexByMobile()] : null;
  });
  isMobile = toSignal<boolean | null>(this.#headerEngine.isMobile$);

  onMenuItem(value: number): void {
    const categories = this.items();

    if (!categories) {
      return;
    }

    const { items, icon = '' } = categories[value];
    const exceptionCase = VALUE_EXCEPTIONS[icon.toLowerCase()];
    const hasItems = items?.length;

    if (hasItems || !exceptionCase) {
      this.activeIndex.set(value);
      return;
    }

    this.#headerEngine.changeMarketFilters(exceptionCase);
    this.closeDropdown();
  }

  itemsHasSubItems(item: CategoriesFilter): CategoriesFilter | null {
    const hasSubItem = item.items?.some((subItem) => !!subItem.items);

    return hasSubItem ? item : null;
  }

  onItem(item: CategoriesFilter, subItem: CategoriesFilter): void {
    const { key: itemKey = 'weapon', value: itemValue } = item;
    const { key: subItemKey = 'weaponType', value: subItemValue } = subItem;
    const exceptionCase = VALUE_EXCEPTIONS[subItemValue.toLowerCase()];
    const defaultCase = { [itemKey]: itemValue, [subItemKey]: subItemValue };

    this.#headerEngine.changeMarketFilters(exceptionCase ?? defaultCase);
    this.closeDropdown();
  }

  onBack(): void {
    this.activeIndex.set(-1);
  }

  closeDropdown(): void {
    this.categoriesMenuOpened.set(false);
  }
}
