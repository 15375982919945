export const KNIFES = [
  'Bayonet',
  'Bowie Knife',
  'Butterfly Knife',
  'Classic Knife',
  'Falchion Knife',
  'Flip Knife',
  'Gut Knife',
  'Huntsman Knife',
  'Karambit',
  'M9 Bayonet',
  'Navaja Knife',
  'Nomad Knife',
  'Paracord Knife',
  'Shadow Daggers',
  'Skeleton Knife',
  'Stiletto Knife',
  'Survival Knife',
  'Talon Knife',
  'Ursus Knife',
];

export const GLOVES = [
  'Bloodhound Gloves',
  'Broken Fang Gloves',
  'Hand Wraps',
  'Driver Gloves',
  'Hydra Gloves',
  'Moto Gloves',
  'Specialist Gloves',
  'Sport Gloves',
];
export const PISTOLS = [
  'CZ75-Auto',
  'Desert Eagle',
  'Dual Berettas',
  'Five-SeveN',
  'Glock-18',
  'P2000',
  'P250',
  'R8 Revolver',
  'Tec-9',
  'USP-S',
];
export const SMGS = ['MAC-10', 'MP7', 'MP9', 'P90', 'PP-Bizon', 'UMP-45', 'MP5-SD'];
export const RIFLES = ['AK-47', 'AUG', 'FAMAS', 'Galil AR', 'M4A4', 'M4A1-S', 'SG 553'];
export const SHOTGUNS = ['MAG-7', 'Nova', 'Sawed-Off', 'XM1014'];
export const MACHINEGUNS = ['Negev', 'M249'];
export const SNIPER_RIFLES = ['AWP', 'G3SG1', 'SCAR-20', 'SSG 08'];

export const OTHER_ITEMS = ['collectible', 'agent', 'graffiti', 'key', 'music kit', 'pass', 'gift'];

export interface IDropdownCategoryItem {
  label: string; //locale
  icon?: string;
  //category.key=value&type.key=value >> ex. weapon=Pistol&weaponType=CZ75-Auto
  category?: {
    key: string;
    value: string;
  };
  type?: {
    key: string;
    value?: string;
  };
  items?: string[];
}

export interface IDropdownConfigItem {
  mainCategory: IDropdownCategoryItem;
  subcategories?: IDropdownCategoryItem[];
}

//firearms category
export const FIREARMS_CATEGORY: IDropdownCategoryItem = {
  label: 'P2P_SETTINGS.FILTERS.GUN',
  icon: 'automate-gun',
};
//firearms sub categories
export const PISTOLS_CATEGORY: IDropdownCategoryItem = {
  label: 'P2P_SETTINGS.FILTERS.PISTOL',
  items: PISTOLS,
  category: {
    key: 'weapon',
    value: 'Pistol',
  },
  type: {
    key: 'weaponType',
  },
};
export const SMGS_CATEGORY: IDropdownCategoryItem = {
  label: 'P2P_SETTINGS.FILTERS.SMG',
  items: SMGS,
  category: {
    key: 'weapon',
    value: 'SMG',
  },
  type: {
    key: 'weaponType',
  },
};
export const RIFLES_CATEGORY: IDropdownCategoryItem = {
  label: 'P2P_SETTINGS.FILTERS.RIFLE',
  items: RIFLES,
  category: {
    key: 'weapon',
    value: 'Rifle',
  },
  type: {
    key: 'weaponType',
  },
};
export const SHOTGUN_CATEGORY: IDropdownCategoryItem = {
  label: 'P2P_SETTINGS.FILTERS.SHOTGUN',
  items: SHOTGUNS,
  category: {
    key: 'weapon',
    value: 'Shotgun',
  },
  type: {
    key: 'weaponType',
  },
};
export const MACHINEGUNS_CATEGORY: IDropdownCategoryItem = {
  label: 'P2P_SETTINGS.FILTERS.MACHINEGUN',
  items: MACHINEGUNS,
  category: {
    key: 'weapon',
    value: 'Machinegun',
  },
  type: {
    key: 'weaponType',
  },
};
export const SNIPER_RIFLES_CATEGORY: IDropdownCategoryItem = {
  label: 'P2P_SETTINGS.FILTERS.SNIPER_RIFLE',
  items: SNIPER_RIFLES,
  category: {
    key: 'weapon',
    value: 'Sniper Rifle',
  },
  type: {
    key: 'weaponType',
  },
};
//knives category
export const KNIFES_CATEGORY: IDropdownCategoryItem = {
  label: 'P2P_SETTINGS.FILTERS.KNIFE',
  icon: 'knife',
  items: KNIFES,
  category: {
    key: 'weapon',
    value: 'Knife',
  },
  type: {
    key: 'weaponType',
  },
};
//gloves category
export const GLOVES_CATEGORY: IDropdownCategoryItem = {
  label: 'P2P_SETTINGS.FILTERS.GLOVES',
  icon: 'gloves',
  items: GLOVES,
  category: {
    key: 'weapon',
    value: 'gloves',
  },
  type: {
    key: 'glovesType',
  },
};
//cases category
export const CASES_CATEGORY: IDropdownCategoryItem = {
  label: 'P2P_SETTINGS.FILTERS.CASES',
  icon: 'cases-icon-v3',
  category: {
    key: 'weapon',
    value: 'other',
  },
  type: {
    key: 'otherType',
    value: 'container',
  },
};
//other category
export const OTHER_CATEGORY: IDropdownCategoryItem = {
  label: 'P2P_SETTINGS.FILTERS.OTHER',
  icon: 'other',
  items: OTHER_ITEMS,
  category: {
    key: 'weapon',
    value: 'other',
  },
  type: {
    key: 'otherType',
  },
};
export const VALUE_EXCEPTIONS: Record<string, { weapon: string; otherType?: string }> = {
  agent: {
    weapon: 'agent',
  },
  'cases-icon-v3': {
    weapon: 'other',
    otherType: 'container',
  },
};
export const DROPDOWN_CONFIG: IDropdownConfigItem[] = [
  {
    mainCategory: FIREARMS_CATEGORY,
    subcategories: [PISTOLS_CATEGORY, SMGS_CATEGORY, RIFLES_CATEGORY, SHOTGUN_CATEGORY, SNIPER_RIFLES_CATEGORY, MACHINEGUNS_CATEGORY],
  },
  { mainCategory: KNIFES_CATEGORY },
  { mainCategory: GLOVES_CATEGORY },
  { mainCategory: CASES_CATEGORY },
  { mainCategory: OTHER_CATEGORY },
];
