import { Component, computed, inject, signal } from '@angular/core';
import { INotification } from '@dev-fast/types';

import { ToastService } from '../toast.service';
import { notificationSlideLeftAnimation } from './notification.animation';
import { NotificationItemComponent } from './notification-item/notification-item.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [NotificationItemComponent],
  animations: [notificationSlideLeftAnimation],
})
export class NotificationComponent {
  readonly #notificationService = inject(ToastService);

  readonly isPaused = signal(false);
  readonly notifications = computed<INotification[]>(() => this.#notificationService.showcaseNotifications());

  pauseNotifications(): void {
    if (this.isPaused()) {
      return;
    }
    this.isPaused.set(true);
    this.#notificationService.pauseTimer();
  }

  resumeNotifications(): void {
    this.isPaused.set(false);
    this.#notificationService.resumeTimer();
  }
}
