import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { provideAnimations } from '@angular/platform-browser/animations';
import { IP2pDepositingItemV2, OrderStatusEnum } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { interval, map, of, startWith, switchMap, takeUntil, timer } from 'rxjs';

import { expansionAnimation } from '@app/games/lib/shared/animations';
import { ReplaceAvatarHashPipeModule } from '@app/shared/pipes';
import { UserAvatarComponent } from '@app/ui/components/index';

import { TradeResultsComponent } from './trade-results/trade-results.component';

@Component({
  standalone: true,
  selector: 'app-proceed-with-trade',
  templateUrl: './proceed-with-trade.component.html',
  styleUrls: ['./proceed-with-trade.component.scss', '../../shared-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expansionAnimation],
  providers: [provideAnimations()],
  imports: [TranslateModule, MatIconModule, DatePipe, NgClass, ReplaceAvatarHashPipeModule, UserAvatarComponent, TradeResultsComponent],
})
export class ProceedWithTradeComponent {
  tradeOffer = input<IP2pDepositingItemV2>();
  isMobileView = input<boolean>(false);
  status = input<OrderStatusEnum>();

  closeModal = output();

  readonly isTradeCompleted = signal(false);

  readonly timeLeft = toSignal(
    of(true).pipe(
      switchMap(() => {
        const timeLeft = 300000 + Date.now();

        return interval(1000).pipe(
          startWith(timeLeft - Date.now()),
          map(() => timeLeft - Date.now()),
          takeUntil(timer(timeLeft - Date.now())),
        );
      }),
    ),
  );

  readonly tradeTimer = computed(() => {
    const time = this.timeLeft();
    const isTradeCompleted = this.isTradeCompleted();

    if (!time || isTradeCompleted) {
      return;
    }

    return time;
  });

  readonly isTradeValid = computed(() => {
    const time = this.timeLeft();
    const isTradeCompleted = this.isTradeCompleted();

    return isTradeCompleted || (time && time > 1000);
  });

  readonly OrderStatusEnum = OrderStatusEnum;

  get buyerAvatarHash(): string {
    const tradeOffer = this.tradeOffer();
    if (!tradeOffer) {
      return '';
    }

    return tradeOffer.order.buyerSteamAvatar;
  }

  get buyerSteamName(): string {
    const tradeOffer = this.tradeOffer();
    if (!tradeOffer) {
      return '';
    }

    return tradeOffer.order.buyerSteamName;
  }

  get buyerSteamLevel(): number {
    const tradeOffer = this.tradeOffer();
    if (!tradeOffer) {
      return 0;
    }

    return tradeOffer.order.buyerSteamLevel;
  }
}
