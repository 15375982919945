import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, input, output } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, debounceTime, distinctUntilChanged, filter, fromEvent, map, Observable, of, startWith, tap } from 'rxjs';

import { IS_SERVER_TOKEN } from '@app/shared/utils';

@Component({
  selector: 'app-search-input',
  standalone: true,
  imports: [TranslateModule, MatIcon, ReactiveFormsModule],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent {
  readonly #destroyRef = inject(DestroyRef);
  readonly #isServer = inject(IS_SERVER_TOKEN);

  value = input<string | null | undefined>('');
  search = output<string>();

  control: FormControl<string | null> = new FormControl('');
  controlChange = toSignal(this.control.valueChanges);
  controlHasValue = computed(() => {
    const value = this.controlChange();
    return !!value && value.length > 0;
  });

  constructor() {
    effect(() => {
      this.control.patchValue(this.value() ?? '');
    });
    const width = (): Observable<number> => {
      return this.#isServer
        ? of(0)
        : fromEvent(window, 'resize').pipe(
            map(() => window.innerWidth),
            startWith(window.innerWidth),
          );
    };
    combineLatest([this.control.valueChanges, width()])
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        debounceTime(500),
        distinctUntilChanged(([prevValue, prevScreenWidth], [curValue, curScreenWidth]) => prevValue === curValue),
        filter(
          ([value, screenWidth]) => value !== this.value() && value !== null && value !== undefined && value !== '' && screenWidth <= 768,
        ),
        tap(([value]) => this.onSearch(value)),
      )
      .subscribe();
  }

  onSearch(value: string | null): void {
    if (!value) {
      return;
    }
    this.search.emit(value);
  }

  onClear(): void {
    this.control.patchValue('');
  }

  onKeyDown($event: KeyboardEvent): void {
    if ($event.key === 'Enter') {
      this.onSearch(this.control.value);
    }
  }
}
