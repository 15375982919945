import { TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostListener,
  inject,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ActiveRoute, FooterCounts, MarketCounts, MenuItem, ModalNames, NewMenu, NewPanel } from '@dev-fast/types';
import { BehaviorSubject, combineLatest, debounceTime, Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChatService } from '@app/core/chatra-service';
import { LayoutTypeService } from '@app/core/layout-service';
import { PortalService } from '@app/core/portal-service';
import { ScrollCommonService } from '@app/core/scroll-service/scroll-common.service';
import { ISeoData, SeoService } from '@app/core/seo-service';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { GofastMarketLayoutService } from './services/gofast-layout.service';
import { MainModalsService } from './services/main-modals.service';

@Component({
  selector: 'app-layout-new-market',
  templateUrl: './gofast-layout-new-market.component.html',
  styleUrls: ['./gofast-layout-new-market.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GofastLayoutNewMarketComponent implements OnInit, AfterViewInit {
  readonly #document = inject<Document>(DOCUMENT);
  readonly #router = inject(Router);
  readonly #moduleService = inject(GofastMarketLayoutService);
  readonly #layoutService = inject(LayoutTypeService);
  readonly #mainModalsService = inject(MainModalsService);
  readonly #portalService = inject(PortalService);
  readonly #viewContainerRef = inject(ViewContainerRef);
  readonly #chatService = inject(ChatService);
  readonly #seoService = inject(SeoService);
  readonly #window: Window | null | undefined = this.#document.defaultView;
  readonly isServer = inject<boolean>(IS_SERVER_TOKEN);
  readonly #scrollCommonService = inject(ScrollCommonService);

  readonly isScrollTop$ = new BehaviorSubject<boolean>(true);

  readonly activeRoute = toSignal<ActiveRoute | null>(this.#moduleService.activeRoute$);
  readonly isStore = computed(() => this.activeRoute() === ActiveRoute.STORE);

  readonly data$: Observable<{
    gameSelectorOpened: boolean;
    counts: FooterCounts;
    marketCounts?: MarketCounts;
    online: number;
    panels?: NewPanel[];
    menu: NewMenu[];
    isAuth: boolean;
    isScrollTop: boolean;
    seoData: ISeoData | null;
  }> = combineLatest([
    this.#moduleService.gameSelectorOpened$,
    this.#moduleService.counts$,
    this.#moduleService.marketCounts$,
    this.#moduleService.online$,
    this.#moduleService.panels$,
    this.#moduleService.menu$,
    this.#moduleService.isAuth$,
    this.isScrollTop$.pipe(debounceTime(200)),
    this.#seoService.seoData$,
  ]).pipe(
    startWith([]),
    map(([gameSelectorOpened, counts, marketCounts, online, panels, menu, isAuth, isScrollTop, seoData, activeRoute]) => ({
      gameSelectorOpened,
      counts,
      marketCounts,
      online,
      panels,
      menu,
      isAuth,
      isScrollTop,
      seoData,
    })),
  );

  readonly PanelEnum = NewPanel;

  isMobileMenuOpened = false;
  readonly navigations: MenuItem[] = [
    {
      path: '/',
      icon: 'assets/img/mat-icons/left-sidebar/store.webp',
    },
    {
      path: '/',
      queryParams: { weapon: 'other', otherType: 'container' },
      icon: '/assets/img/lobby/sidebar-game-1.svg',
    },
    {
      path: '/case-battle',
      icon: 'assets/img/mat-icons/left-sidebar/case-battle.webp',
    },
  ];
  @ViewChild('modalTmpl') modalTmpl: TemplateRef<unknown> | undefined;
  @ViewChild('scroll') scroll!: ElementRef;

  @HostListener('window:scroll') watchScroll(): void {
    this.isScrollTop$.next(window.scrollY === 0);
  }

  constructor() {
    this.#scrollCommonService.disableScrollRestoration();
    this.#scrollCommonService.handleScrollOnNavigation();
  }

  ngOnInit(): void {
    this.#mainModalsService.registerModals();
    this.#moduleService.init();
  }

  ngAfterViewInit(): void {
    if (this.modalTmpl) {
      this.#portalService.routableModalPortal.value = new TemplatePortal(this.modalTmpl, this.#viewContainerRef, {});
    }
  }

  onResize(): void {
    if (!this.isServer) {
      const width = this.scroll.nativeElement.clientWidth;
      this.#layoutService.setBreakpoints(width);
    }
  }

  scrollTop(): void {
    if (!this.#window) {
      return;
    }
    this.#window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  openTechSupportDialog(): void {
    if (this.#chatService.hasChat()) {
      this.#chatService.openChat();
      return;
    }
    this.#moduleService.openModal(ModalNames.TECH_SUPPORT_SELECT_METHOD);
  }

  openMarket(): void {
    this.#router.navigate(['/']);
  }
}
