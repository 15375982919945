<div class="tw-flex tw-flex-col tw-self-stretch tw-font-medium tw-max-w-80">
  <div class="triangle tw-self-center tw-w-4 tw-aspect-[2] tw-bg-gray-750"></div>
  <div class="tw-flex tw-flex-col tw-p-6 tw-w-full tw-rounded-xl tw-bg-gray-750">
    <div class="tw-flex tw-gap-5 tw-text-sm tw-leading-[150%] tw-text-gray-150">
      <app-sih-custom-icon
        [subIcon]="'warning-circle'"
        class="tw-shrink-0 tw-aspect-[1.64] tw-w-[3.75rem] tw-max-h-[2.812rem]"
      />
      <p>{{ 'SIH.FIRST_MODAL.HEADER' | translate }}</p>
    </div>
    <a
      class="tw-text-center !tw-no-underline tw-px-4 tw-py-2.5 tw-mt-4 tw-text-xs tw-tracking-wide tw-leading-4 tw-bg-yellow-400 tw-rounded-md tw-text-gray-800"
      [href]="sihLink"
      target="_blank"
    >
      {{ 'SIH.FIRST_MODAL.BUTTON' | translate }}
    </a>
  </div>
</div>
