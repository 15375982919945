import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-first-banner',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './first-banner.component.html',
  styleUrl: './first-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstBannerComponent {
  readonly #router = inject(Router);

  onView(): void {
    this.#router.navigate(['/'], { queryParams: { weapon: 'other', otherType: 'container' } });
  }
}
