import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { IP2pDepositItem } from '@dev-fast/types';

import { LotCardItem, LotCardItemInfoComponent } from '@app/new-market/p2p/lot-card-item-info/lot-card-item-info.component';
import {
  LotCardPriceInfoComponent,
  LotCardPriceInfoEditableComponent,
} from '@app/new-market/p2p/lot-card-price-info/lot-card-price-info.component';
import { getSkinColorsPalette, SKIN_RARITY_V2 } from '@app/shared/utils';

@Component({
  selector: 'app-exchange-basket-card',
  standalone: true,
  templateUrl: './exchange-basket-card.component.html',
  styleUrl: './exchange-basket-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LotCardPriceInfoComponent, LotCardItemInfoComponent, MatIcon, LotCardPriceInfoEditableComponent, NgTemplateOutlet],
})
export class ExchangeBasketCardComponent {
  depositingItem = input<IP2pDepositItem>();
  isEditable = input<boolean>(false);
  nativeBreakpoint = input<string>();

  remove = output<void>();
  priceChanged = output<number | null | undefined>();

  lotInfoArr = computed<LotCardItem[]>(() => {
    const item = this.depositingItem();
    if (!item) {
      return [];
    }
    return [
      {
        baseItem: item.baseItem,
        color: getSkinColorsPalette((item.baseItem.rarity || item.baseItem.color).toLowerCase(), SKIN_RARITY_V2),
        float: item.float,
        stickers: item.stickers,
      },
    ];
  });

  priceInfo = computed(() => {
    const depositItem = this.depositingItem();
    return {
      price: depositItem?.extra.price || 0,
      overprice: depositItem?.extra.increase || 0,
      recommendedPrice: depositItem?.price || 0,
      minPrice: depositItem?.priceRange.start || 0,
    };
  });

  onRemove(): void {
    this.remove.emit();
  }

  onPriceChange($event: number | null | undefined): void {
    this.priceChanged.emit($event);
  }
}
