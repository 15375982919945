<div class="tw-grid tw-items-center tw-w-full">
  <app-lot-card-item-info [items]="lotInfoArr()">
    @if (nativeBreakpoint() === 'xxs') {
      <ng-container *ngTemplateOutlet="priceTemplate"></ng-container>
    }
  </app-lot-card-item-info>
  @if (nativeBreakpoint() !== 'xxs') {
    <ng-container *ngTemplateOutlet="priceTemplate"></ng-container>
  }
</div>
<button
  (click)="onRemove()"
  class="tw-text-gray-450 hover:tw-text-gray-200"
>
  <mat-icon
    svgIcon="close"
    class="!tw-size-6"
  ></mat-icon>
</button>
<ng-template #priceTemplate>
  @if (priceInfo(); as priceInfo) {
    @if (isEditable()) {
      <app-lot-card-price-info-editable
        class="sm:tw-min-w-40 tw-max-w-44"
        [price]="priceInfo.price"
        [overprice]="priceInfo.overprice"
        [recommendedPrice]="priceInfo.recommendedPrice"
        [minPrice]="priceInfo.minPrice"
        (changePrice)="onPriceChange($event)"
      />
    } @else {
      <app-lot-card-price-info
        class="sm:tw-min-w-40"
        [price]="priceInfo.price"
        [overprice]="priceInfo.overprice"
        [recommendedPrice]="priceInfo.recommendedPrice"
      />
    }
  }
</ng-template>
