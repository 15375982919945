@if (item) {
  <div
    class="lot"
    [ngClass]="bodyBorderClass"
  >
    <!-- Lot status header -->
    <div class="lot__status tw-flex tw-items-center tw-justify-between">
      @if (lotStatusTitleLocale) {
        <div class="lot__status-title tw-font-bold">
          {{ 'P2P_WIDGETS.RENEW_SIDEBAR.' + lotStatusTitleLocale | translate }}
        </div>
      }
      @if (offerTimer$ | async; as offerTimer) {
        @if (showTimer && isTimerActive && !isLotPaused) {
          <div class="lot__status-utility tw-flex tw-items-center tw-gap-2">
            <span class="lot__status-timer lot__text-sm tw-flex tw-items-center">
              @if (isLotPaused) {
                <span>{{ 'P2P_WIDGETS.PURCHASE.BLOCKED_FOR' | translate }} </span>
              }
              <mat-icon svgIcon="clock--design"></mat-icon>
              {{ offerTimer | countdownFormat }}
            </span>
            @if (statuses.NEW === item.status && !isLotPaused && !isLotDeleteTemplate) {
              <div class="tw-flex tw-items-center tw-gap-2">
                @if (!item.disableActions) {
                  <div
                    class="utility-btn tw-cursor-pointer tw-flex tw-items-center"
                    (click)="operateDeleteAction()"
                  >
                    <mat-icon svgIcon="close-circle-bold"></mat-icon>
                  </div>
                }
                @if (!item.disableActions) {
                  <div
                    class="utility-btn tw-cursor-pointer tw-flex tw-items-center"
                    (click)="onPauseItem(item.id)"
                  >
                    <mat-icon svgIcon="pause-circle"></mat-icon>
                  </div>
                }
              </div>
            }
          </div>
        }
      }
    </div>
    <!-- Lot card block -->
    <div class="tw-relative tw-p-4">
      @if (isLotPaused) {
        <mat-icon
          class="pause-icon absolute"
          svgIcon="pause-circle"
        ></mat-icon>
      }
      <app-ui-p2p-lot-card
        [class.lot-paused]="isLotPaused"
        [items]="item.items"
        [stickersLeft]="true"
        [isSell]="true"
        (showItemDetails)="onShowLotDetailsModal(item)"
      ></app-ui-p2p-lot-card>
    </div>
    <!-- Lot price block -->
    <div class="lot__current-offer lot__text-sm flex fx-align-center fx-justify-between p-2">
      <span>{{ priceStatusLocale | translate }}</span>
      <app-currency
        class="price"
        [value]="item.sellerPrice"
      />
    </div>
    <!-- Lot footer block -->
    <div class="lot__actions lot__text-sm">
      @if (statuses.NEW === item.status) {
        <div class="wait_offer">
          @if (isLotDeleteTemplate) {
            {{ 'P2P_WIDGETS.PURCHASE.AGREE_DELETE' | translate }}
            <div class="flex gap-2 pt-sm-5">
              <button
                class="btn btn-primary--design"
                (click)="onDeleted(item.id)"
              >
                {{ 'P2P_WIDGETS.PURCHASE.DELETE_CONFIRM' | translate }}
              </button>
              <button
                class="btn btn-basic--design"
                (click)="operateDeleteAction()"
              >
                {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.CANCEL_BUTTON' | translate }}
              </button>
            </div>
          } @else {
            {{ 'P2P_WIDGETS.PURCHASE.OFFER_WAITING' | translate }}
          }
        </div>
      }
      @if (isBidOnOrder) {
        {{ 'P2P_WIDGETS.DEPOSITING.YOU_CAN_SELL_NOW' | translate }}
        <button
          class="btn btn-primary--design mt-sm-5"
          (click)="onSellNow(item.id)"
          [disabled]="item.isActive"
        >
          {{ 'P2P_WIDGETS.DEPOSITING.SELL_NOW' | translate }}
          @if (item.isActive) {
            <mat-spinner
              class="primary-spinner"
              diameter="16"
            ></mat-spinner>
          }
        </button>
      }
      @if (statuses.WAIT_FOR_TRADE === item.status || statuses.WAIT_FOR_TRADE_CONFIRM === item.status) {
        @if (offerTimer$ | async; as offerTimer) {
          {{ 'P2P_WIDGETS.DEPOSITING.SEND_TRADE' | translate }}
          @if (item.order?.buyerTradeLink?.length && item.items?.[0]?.steamItemPosition) {
            <button
              class="btn btn-primary--design mt-sm-5"
              [disabled]="offerTimer < 1000"
              (click)="onSendTrade()"
            >
              {{ 'P2P_WIDGETS.DEPOSITING.SEND_TRADE' | translate }} : {{ offerTimer | countdownFormat }}
            </button>
          } @else {
            <button
              class="btn btn-primary--design mt-sm-5"
              [disabled]="true"
            >
              {{ 'P2P_WIDGETS.DEPOSITING.SEND_TRADE' | translate }}
              <mat-spinner
                class="primary-spinner"
                diameter="16"
              ></mat-spinner>
            </button>
          }
        }
      }
      @if (statuses.WAIT_FOR_CONFIRM === item.status) {
        {{ 'P2P_WIDGETS.PURCHASE.OFFER_IS_OVER' | translate }}
        <div class="flex gap-2 pt-sm-5">
          <button
            class="btn btn-primary--design"
            (click)="onConfirm(item.id)"
          >
            {{ 'ACCEPT' | translate }}
          </button>
          <button
            class="btn btn-basic--design"
            (click)="onDecline(item.id)"
          >
            {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.CANCEL_BUTTON' | translate }}
          </button>
        </div>
      }
      @if (statuses.WAIT_FOR_BUYER_ACCEPT === item.status) {
        @if (offerTimer$ | async; as timer) {
          {{ (timer && timer > 1000 ? 'P2P_WIDGETS.DEPOSITING.WAIT_FOR_BUYER_ACCEPT' : 'P2P_WIDGETS.WARN.TIMEOUT') | translate }}
        }
      }
      @if (statuses.PAUSED === item.status) {
        <button
          class="btn btn-outline--design"
          (click)="onResumeItem(item.id)"
          [class.btn-pause-fill]="isTimerActive"
          [style.--fill-percent]="pauseBtnFillPercent + '%'"
          [disabled]="isTimerActive"
        >
          @if (isTimerActive) {
            @if (offerTimer$ | async; as timer) {
              {{ 'P2P_WIDGETS.PURCHASE.BLOCKED_FOR' | translate }} {{ timer | countdownFormat }}
            }
          } @else {
            {{ 'P2P_WIDGETS.PURCHASE.UNPAUSE' | translate }}
          }
        </button>
      }
      @if (lotCanceledReasonLocale) {
        <div class="offer-canceled">
          {{ 'P2P_WIDGETS.DEPOSITING.' + lotCanceledReasonLocale | translate }}
          <button
            class="btn btn-basic--design mt-sm-5"
            (click)="onDeleted(item.id)"
          >
            {{ 'CLOSE' | translate }}
          </button>
        </div>
      }
    </div>
  </div>
}
