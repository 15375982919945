<div class="tw-flex tw-w-full tw-h-full max-md:tw-flex-col max-md:tw-h-full">
  <!-- left-->
  <div
    class="tw-flex tw-flex-col tw-gap-6 tw-bg-gray-650 tw-p-4 tw-pt-6 smm:tw-p-8 md:tw-rounded-l-3xl max-md:tw-h-full tw-min-w-[64%] max-md:tw-rounded-t-3xl max-md:tw-w-full"
  >
    <h2 class="tw-font-medium tw-text-white tw-text-lg">{{ 'P2P_WIDGETS.REDESIGN.SELL_MODAL.ADD_ITEMS' | translate }}</h2>
    <div class="tw-flex tw-justify-between tw-items-center tw-gap-2">
      <app-simple-sorting
        class="tw-shrink-0"
        [items]="sortingMethods()"
        (methodChanged)="onSortingTypeChange($event)"
      />
      <app-ui-tabs
        class="button-tabs tw-w-full md:tw-hidden"
        [(selectedId)]="selectedId"
      >
        @for (option of tabOptions(); track option.id) {
          <app-ui-tab
            class="tw-w-full tw-relative"
            [id]="option.id"
            >{{ option.labelLocale | translate: { count: kitCountText() } }}
          </app-ui-tab>
        }
      </app-ui-tabs>
      <div class="tw-flex tw-gap-4 tw-items-center">
        <div
          class="tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-bg-gray-700 tw-rounded-full max-sm:tw-w-12 max-sm:tw-h-12 sm:max-md:tw-w-9 sm:max-md:tw-h-9 tw-w-11 tw-h-11"
          (click)="onClearExchangeBasket()"
        >
          <mat-icon
            class="tw-text-gray-400 !tw-w-5 !tw-h-5"
            svgIcon="market--delete_exchange"
          />
        </div>
      </div>
    </div>
    <ng-scrollbar class="ng-scrollbar">
      <div class="tw-flex tw-flex-col">
        @for (entity of selectedItems(); track $index) {
          <!-- Нет данных для пересборки китами, поэтому пока так полежит -->
          @if (false) {
            <!-- @if (!$first) {
              <div class="tw-w-full tw-h-px tw-bg-gray-550"></div>
            }
            <div>{{ 'LOCAL.ITEMS' | translate }} {{ ['items'].length }}</div>

            @for (kitItem of []; track $index) {
              <app-exchange-basket-card
                class="tw-mb-2 sm:tw-mb-[0.875rem] tw-pr-3"
                [depositingItem]="kitItem"
                [isEditable]="!isKitTab()"
                (remove)="removeSelectedItem(kitItem)"
                (priceChanged)="onPriceChange($event, kitItem)"
              />
            }
            <div class="tw-w-full tw-h-px tw-bg-gray-550"></div> -->
          } @else {
            <app-exchange-basket-card
              class="tw-mb-2 sm:tw-mb-[0.875rem] tw-pr-3"
              [depositingItem]="entity"
              [nativeBreakpoint]="nativeBreakpoint()"
              [isEditable]="!isKitTab()"
              (remove)="removeSelectedItem(entity)"
              (priceChanged)="onPriceChange($event, entity)"
            />
          }
        }
      </div>
    </ng-scrollbar>

    <div class="tw-flex tw-items-baseline tw-gap-2 tw-w-full md:tw-hidden">
      @if (!openDepositSettings() && canShowSlider()) {
        <div class="tw-fixed tw-left-0 tw-bottom-24 tw-p-4 tw-pb-0 tw-bg-gray-650 tw-w-screen tw-z-10">
          @if (canProceedWithTrade()) {
            <div class="tw-flex tw-flex-col tw-gap-2">
              <div class="tw-flex tw-justify-between">
                <span class="tw-text-sm tw-font-medium">
                  {{ 'P2P_WIDGETS.REDESIGN.SELL_MODAL.TOTAL_MARKUP' | translate }}
                </span>
                <button
                  class="tw-text-gray-400 tw-text-xs hover:tw-text-gray-200"
                  (click)="resetOverprice(); onToggleDepositSettings()"
                >
                  {{ 'P2P_WIDGETS.REDESIGN.SELL_MODAL.RESET' | translate }}
                </button>
              </div>
              @if (overpriceText(); as overpriceText) {
                <mat-slider
                  class="p2p-setup-slider"
                  [class.low]="overpriceText.low"
                  [class.medium]="overpriceText.medium"
                  [class.high]="overpriceText.high"
                  [discrete]="false"
                  [min]="minStandardOverprice()"
                  [max]="100"
                  [step]="1"
                >
                  <input
                    matSliderThumb
                    [formControl]="overpriceControl"
                  />
                </mat-slider>
              }

              <div class="tw-text-gray-400 tw-text-xs tw-flex tw-justify-between">
                <span>{{ 'P2P_WIDGETS.DEPOSITING.QUICK_SALE' | translate }}</span>
                <span>{{ 'P2P_WIDGETS.DEPOSITING.SLOW' | translate }}</span>
              </div>
            </div>
          } @else {
            <div class="tw-flex tw-flex-col">
              <button
                class="btn btn-primary--design tw-rounded-xl tw-w-full"
                (click)="handleErrorAction(permissions())"
                [disabled]="isActionBtnDisabled()"
              >
                {{ permissionErrorBtnText() | translate: { days: permissions()?.canTrade?.days || 0 } | uppercase }}
              </button>
            </div>
          }
        </div>
      }
      <button
        class="btn btn-basic--design sm:tw-size-14 tw-text-xl tw-rounded-xl"
        [disabled]="!canShowSlider()"
        (click)="onToggleDepositSettings()"
      >
        %
      </button>
      <button
        [disabled]="canShowSihTooltip() || isActionBtnDisabled() || (isKitTab() && !canAddKit())"
        (click)="sellSelectedItems()"
        class="tw-w-full disabled:tw-bg-gray-550 disabled:tw-text-gray-400 tw-inline tw-px-8 tw-py-4 tw-mt-5 tw-text-base tw-font-medium tw-bg-blue-500 tw-rounded-xl tw-text-gray-100"
      >
        @if (canShowSihTooltip()) {
          {{ 'SIH.FIRST_MODAL.HEADER' | translate }}
        } @else {
          {{ 'P2P_WIDGETS.REDESIGN.SELL_MODAL.SELL_BUTTON2' | translate }}
          @if (selectedSumWithOverprice(); as price) {
            <app-currency [value]="price" />
          }
        }
      </button>
    </div>
  </div>

  <!-- right-->
  <div class="tw-flex tw-flex-col md:tw-rounded-r-3xl tw-justify-start tw-p-8 tw-bg-gray-700 tw-w-[36%] tw-gap-6 max-md:tw-hidden">
    <div
      class="tw-rounded-full tw-cursor-pointer tw-flex tw-justify-end tw-text-gray-400 hover:tw-text-gray-200"
      (click)="onClose()"
    >
      <mat-icon
        svgIcon="close"
        class="!tw-size-4"
      ></mat-icon>
    </div>
    <app-ui-tabs
      class="button-tabs tw-w-full tw-flex"
      [(selectedId)]="selectedId"
    >
      @for (option of tabOptions(); track option.id) {
        <app-ui-tab
          class="tw-w-full tw-relative"
          [id]="option.id"
          >{{ option.labelLocale | translate: { count: kitCountText() } }}
        </app-ui-tab>
      }
    </app-ui-tabs>
    @if (canShowSlider() && canProceedWithTrade()) {
      <div class="tw-flex tw-flex-col tw-gap-2">
        <div class="tw-flex tw-justify-between">
          <div
            class="tw-flex tw-gap-2 tw-text-gray-150 tw-cursor-pointer hover:tw-text-gray-200"
            (click)="onToggleDepositSettings()"
          >
            <mat-icon
              [svgIcon]="openDepositSettings() ? 'arrow-top-compact' : 'arrow-bottom-compact'"
              class="!tw-size-4"
            ></mat-icon>
            <span class="tw-text-sm tw-font-medium">
              {{ 'P2P_WIDGETS.REDESIGN.SELL_MODAL.TOTAL_MARKUP' | translate }}
            </span>
          </div>
          <button
            class="tw-text-gray-400 tw-text-xs hover:tw-text-gray-200"
            (click)="resetOverprice()"
          >
            {{ 'P2P_WIDGETS.REDESIGN.SELL_MODAL.RESET' | translate }}
          </button>
        </div>
        @if (openDepositSettings()) {
          @if (overpriceText(); as overpriceText) {
            <mat-slider
              class="p2p-setup-slider"
              [class.low]="overpriceText.low"
              [class.medium]="overpriceText.medium"
              [class.high]="overpriceText.high"
              [discrete]="false"
              [min]="minStandardOverprice()"
              [max]="100"
              [step]="1"
            >
              <input
                matSliderThumb
                [formControl]="overpriceControl"
              />
            </mat-slider>
          }

          <div class="tw-text-gray-400 tw-text-xs tw-flex tw-justify-between">
            <span>{{ 'P2P_WIDGETS.DEPOSITING.QUICK_SALE' | translate }}</span>
            <span>{{ 'P2P_WIDGETS.DEPOSITING.SLOW' | translate }}</span>
          </div>
        }
      </div>
    }

    @if (isKitTab() && !canAddKit()) {
      <span class="tw-text-gray-400 tw-text-base tw-font-medium tw-leading-6 tw-tracking-[0.03375rem]">
        {{ 'P2P_WIDGETS.REDESIGN.SELL_MODAL.DETAILS' | translate }}
      </span>
    } @else {
      <div class="tw-w-full tw-h-px tw-bg-gray-550"></div>
      <!-- prices -->
      <div class="tw-flex tw-flex-col tw-gap-3">
        <div class="tw-flex tw-gap-5 tw-justify-between tw-mt-3 tw-w-full tw-font-medium">
          <div class="tw-my-auto tw-text-sm tw-text-gray-150">{{ countSelectedItems() }} {{ 'items' | translate }}</div>

          @if (selectedSumWithOverprice(); as price) {
            <app-currency
              class="summary-price"
              [value]="price"
            />
          }
        </div>
      </div>

      <div class="tw-flex tw-flex-col">
        @if (canProceedWithTrade()) {
          <button
            [disabled]="canShowSihTooltip() || isActionBtnDisabled()"
            (click)="sellSelectedItems()"
            class="disabled:tw-bg-gray-550 disabled:tw-text-gray-400 tw-inline tw-px-8 tw-py-4 tw-mt-5 tw-text-base tw-font-medium tw-bg-blue-500 tw-rounded-xl tw-text-gray-100"
          >
            {{ 'P2P_WIDGETS.REDESIGN.SELL_MODAL.SELL_BUTTON' | translate }}
          </button>
        } @else {
          <div class="tw-flex tw-flex-col">
            <button
              class="btn btn-primary--design tw-rounded-xl tw-w-full"
              (click)="handleErrorAction(permissions())"
              [disabled]="isActionBtnDisabled()"
            >
              {{ permissionErrorBtnText() | translate: { days: permissions()?.canTrade?.days || 0 } | uppercase }}
            </button>
          </div>
        }
        @if (canShowSihTooltip()) {
          <app-sih-check-tooltip />
        }
      </div>
    }
  </div>
</div>
