<img
  src="/assets/img/banners/new-market/beautyful-picture.jpg"
  alt="beautiful-picture"
/>
<div class="banner-content tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-pr-1">
  <div class="title tw-mb-8">
    {{ 'P2P_WIDGETS.REDESIGN.BANNER.HEADER' | translate }}
  </div>
  <div class="description tw-mb-10 tw-w-full sm:tw-w-3/4 tw-max-w-[29rem]">
    {{ 'P2P_WIDGETS.REDESIGN.BANNER.BODY' | translate }}
  </div>
  <button
    class="button btn btn-primary--design tw-w-fit tw-rounded-lg"
    (click)="onView()"
  >
    {{ 'P2P_WIDGETS.REDESIGN.BANNER.BUTTON' | translate }}
  </button>
</div>
