<div
  class="new-market-header"
  [class.categories--opened]="categoriesMenuOpened"
>
  <div class="new-market-header__content">
    <app-ui-brand
      class="brand-block"
      [link]="'/'"
    ></app-ui-brand>

    <div class="search-block tw-flex tw-justify-center tw-items-center tw-gap-2 md:tw-gap-3">
      <app-category-menu [(categoriesMenuOpened)]="categoriesMenuOpened"></app-category-menu>
      <app-search-input
        [value]="marketQuery()"
        (search)="changeQuery($event)"
      ></app-search-input>
    </div>
    <div class="notifications">
      <app-notifications-v2>
        <mat-icon
          icon
          svgIcon="bell-outline"
        ></mat-icon>
      </app-notifications-v2>
    </div>
    <div class="items-block tw-flex tw-items-center tw-justify-end tw-h-full tw-gap-3">
      @if (user() !== undefined && showOnClient()) {
        @if (user()) {
          <a
            class="header-btn items tw-flex tw-justify-center tw-items-center tw-gap-2 tw-font-bold tw-rounded-full tw-text-nowrap tw-p-4 tw-h-full tw-text-base"
            routerLink="/inventory"
          >
            <mat-icon svgIcon="automate-gun" />
            {{ inventoryShortInfo()?.inventoryCount }}
          </a>
          <a
            class="header-btn balance tw-flex tw-justify-center tw-items-center tw-gap-2 tw-font-bold tw-rounded-full tw-text-nowrap tw-p-4 tw-h-full tw-text-base"
            [routerLink]="[{ outlets: { modal: ['transaction', 'refill'] } }]"
            [queryParamsHandling]="'preserve'"
          >
            <app-currency [value]="userBalance()" />
          </a>
        } @else {
          <app-ui-button
            class="yellow-color login-btn outline"
            (click)="onLogin()"
          >
            {{ 'LOGIN' | translate }}
          </app-ui-button>
        }
        <app-header-action-menu
          [user]="user()"
          [locales]="locales()"
          [locale]="currentLocale()"
          [canLevelUp]="canLevelUp()"
          [userBalance]="userBalance()"
          [hideAvatarBorder]="true"
          [hideAvatarChevron]="true"
        >
          <div
            class="tw-flex tw-items-center tw-justify-center tw-bg-gray-700 tw-text-gray-200 tw-rounded-full tw-size-4 tw-absolute tw-left-2/3 tw-bottom-0"
          >
            <mat-icon
              class="!tw-size-2"
              svgIcon="arrow-bottom-compact"
            ></mat-icon>
          </div>
        </app-header-action-menu>
      } @else {
        <app-ui-spinner-loader></app-ui-spinner-loader>
      }
    </div>
  </div>
</div>
