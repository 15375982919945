import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { P2pMarketService } from '@core/p2p-services/p2p-market.service';
import { IFilterFormContent, IInventoryShortInfo, ILocales, IUserDetailed, MarketSortingTypes, TransactionWay } from '@dev-fast/types';
import { map, Observable } from 'rxjs';

import { AuthService } from '@app/auth';
import { InventoryService } from '@app/core/inventory-service';
import { LanguageService } from '@app/core/language-service';
import { UserService } from '@app/core/user-service';
import { HeaderEngine } from '@app/new-market/new-market-header/symbols';
import { compareBreakpoints } from '@app/shared/utils';

@Injectable()
export class HeaderEngineService implements HeaderEngine {
  readonly #router = inject(Router);
  readonly #languageService = inject(LanguageService);
  readonly #authService = inject(AuthService);
  readonly #userService = inject(UserService);
  readonly #inventoryService = inject(InventoryService);
  readonly #marketService = inject(P2pMarketService);

  readonly locales$: Observable<ILocales[]> = this.#languageService.locales$;
  readonly currentLocale$: Observable<ILocales> = this.#languageService.currentLocale$;
  readonly user$: Observable<IUserDetailed | null | undefined> = this.#userService.user$;
  readonly canLevelUp$: Observable<boolean> = this.#userService.canLevelUp$;
  readonly userBalance$: Observable<number> = this.#userService.userBalance$;
  readonly inventoryShortInfo$: Observable<IInventoryShortInfo> = this.#inventoryService.inventoryShortInfo$;
  readonly marketFilters$: Observable<IFilterFormContent<MarketSortingTypes>> = this.#marketService.filters$;

  readonly isMobile$ = this.#marketService.breakpoints$.pipe(
    map((breakpoint) => (breakpoint && breakpoint.native ? !compareBreakpoints(breakpoint.native, 'xs') : true)),
  );

  login = (): void => this.#authService.openLoginProvidersModal();

  openInventory(): void {
    this.#router.navigate(['/inventory']);
  }

  openTransactions(way: TransactionWay): void {
    this.#router.navigate([{ outlets: { modal: ['transaction', way] } }], { queryParamsHandling: 'preserve' });
  }

  changeMarketFilters(filter: Record<string, string>): void {
    this.#router.navigate([this.#languageService.getCurrentLangUrl('/')], { queryParams: filter });
  }
}
