import { Component, inject } from '@angular/core';
import { fromEvent } from 'rxjs';

import { SeoService } from '@app/core/seo-service';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { environment } from '../environments/environment';

@Component({
  selector: 'market-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  readonly #isServer = inject(IS_SERVER_TOKEN);
  readonly #seoService = inject(SeoService);

  constructor() {
    this.#seoService.start();
    if (this.#isServer) {
      return;
    }
    this.#handleNonServerTasks();
  }

  #handleNonServerTasks(): void {
    this.#setupResizeWatcher();
    this.#redirectIfHttp();
  }

  #redirectIfHttp(): void {
    if (window.location.protocol === 'http:' && environment.production) {
      window.location.href = location.href.replace('http', 'https');
    }
  }

  #setupResizeWatcher(): void {
    this.#updatePosition();
    fromEvent(window, 'resize').subscribe(() => this.#updatePosition());
  }

  #updatePosition(): void {
    const vh = window.innerHeight * 0.01;
    // TODO: will be fixed in SSR v16 angular https://github.com/angular/angular/issues/42170

    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
