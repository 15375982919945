import { ChangeDetectionStrategy, Component, computed, input, model, output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ISelectionMenuItem } from '@app/ui/components/lib/form-elements/autocomplete-menu/shared';

@Component({
  selector: 'app-simple-sorting',
  standalone: true,
  templateUrl: './simple-sorting.component.html',
  styleUrl: './simple-sorting.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, MatIcon],
})
export class SimpleSortingComponent {
  items = input.required<[ISelectionMenuItem, ISelectionMenuItem]>();
  sortingIndex = model<number>(0);
  selectedMethod = computed(() => this.items()[this.sortingIndex()]);
  methodChanged = output<ISelectionMenuItem>();

  onToggleSorting(): void {
    this.sortingIndex.set(this.sortingIndex() === 0 ? 1 : 0);
    this.methodChanged.emit(this.selectedMethod());
  }
}
