<div
  class="trade-created tw-grid"
  @expansionAnimation
>
  @if (status() !== OrderStatusEnum.COMPLETED) {
    <div class="trade-header tw-flex tw-justify-between">
      <div
        class="tw-font-medium tw-text-xl tw-text-gray-100 tw-mb-6"
        [class.tw-text-center]="isMobileView()"
      >
        @if (isTradeValid()) {
          {{ 'P2P_WIDGETS.SIH_TRADE.STEAM_GUARD.HEADER' | translate }}
        } @else {
          {{ 'P2P_WIDGETS.SIH_TRADE.STEAM_GUARD.ERROR.HEADER' | translate }}
        }
      </div>

      @if (!isMobileView()) {
        @if (tradeTimer() && isTradeValid()) {
          <div class="tw-font-medium text-size-2rem tw-text-yellow-500">{{ tradeTimer() | date: 'mm:ss' }}</div>
        }
        @if (!isTradeValid()) {
          <div class="tw-font-medium text-size-2rem tw-text-red-500">00:00</div>
        }
      }
    </div>

    @if (isMobileView()) {
      <div class="confirm-trade tw-pt-6">
        @if (isTradeCompleted()) {
          <button class="btn btn-basic--design tw-px-6 tw-py-3 !tw-rounded-xl tw-pointer-events-none tw-w-full !tw-text-gray-300">
            <div class="tw-flex tw-items-center tw-justify-center">
              <mat-icon
                class="loading !tw-w-4 !tw-h-4"
                svgIcon="refresh-line"
              />
            </div>
            {{ 'P2P_WIDGETS.SIH_TRADE.STEAM_GUARD.WAITING' | translate }}
          </button>
        } @else {
          <button
            class="btn btn-default--design tw-px-6 tw-py-3 !tw-rounded-xl tw-w-full"
            (click)="isTradeCompleted.set(true)"
          >
            {{ 'P2P_WIDGETS.SIH_TRADE.STEAM_GUARD.CONFIRM' | translate }}
          </button>
        }
      </div>
    }
    <div
      class="guard-example tw-flex tw-gap-8 tw-items-center tw-mb-6 tw-border-b tw-border-solid tw-border-gray-500 tw-min-w-52"
      [ngClass]="{ '!tw-border-none tw-justify-self-center tw-pt-6 !tw-mb-0': isMobileView() }"
    >
      <div class="tw-relative">
        <img
          src="/assets/img/p2p/steam-guard-example.svg"
          alt=""
        />
        <div class="buyer-info guard-example tw-absolute tw-flex tw-items-center tw-gap-3">
          <app-ui-user-avatar [url]="buyerAvatarHash | replaceAvaHash"> </app-ui-user-avatar>
          <div class="tw-flex tw-flex-col tw-gap-1">
            <div class="tw-font-normal tw-text-xs tw-text-gray-100">{{ 'Trade to' }}</div>
            <div class="tw-flex tw-gap-1 tw-items-center">
              <div class="buyer-name tw-font-normal tw-text-xs tw-text-gray-100">{{ buyerSteamName }}</div>
              @if (buyerSteamLevel) {
                <div
                  class="tw-flex tw-item-center tw-justify-center tw-font-normal tw-text-xs tw-text-gray-300 tw-border-2 tw-border-solid tw-border-[#a661b0] tw-rounded-full tw-w-[1.375rem] tw-h-[1.375rem]"
                >
                  {{ buyerSteamLevel }}
                </div>
              }
            </div>
          </div>
        </div>

        @if (isMobileView() && tradeTimer() && isTradeValid()) {
          <div class="timer tw-absolute tw-font-medium text-size-2rem tw-text-yellow-500">{{ tradeTimer() | date: 'mm:ss' }}</div>
        } @else if (!isTradeValid()) {
          <mat-icon
            class="tw-absolute warning-sign !tw-w-14 !tw-h-auto tw-text-red-500"
            svgIcon="warning"
          ></mat-icon>
        }
      </div>
      @if (!isMobileView()) {
        @if (isTradeValid()) {
          <div class="tw-flex tw-flex-col tw-gap-6 tw-pb-5">
            <div class="tw-font-normal tw-text-sm tw-text-gray-150 tw-whitespace-pre-line">
              {{ 'P2P_WIDGETS.SIH_TRADE.STEAM_GUARD.BODY1' | translate }}
            </div>
            <div class="tw-font-normal tw-text-sm tw-text-gray-300 tw-whitespace-pre-line">
              {{ 'P2P_WIDGETS.SIH_TRADE.STEAM_GUARD.BODY' | translate }}
            </div>
            @if (isTradeCompleted()) {
              <button class="btn btn-basic--design tw-px-6 tw-py-3 !tw-rounded-xl tw-pointer-events-none tw-w-min !tw-text-gray-300">
                <div class="tw-flex tw-items-center tw-justify-center">
                  <mat-icon
                    class="loading !tw-w-4 !tw-h-4"
                    svgIcon="refresh-line"
                  />
                </div>
                {{ 'P2P_WIDGETS.SIH_TRADE.STEAM_GUARD.WAITING' | translate }}
              </button>
            } @else {
              <button
                class="btn btn-default--design tw-px-6 tw-py-3 !tw-rounded-xl tw-w-min"
                (click)="isTradeCompleted.set(true)"
              >
                {{ 'P2P_WIDGETS.SIH_TRADE.STEAM_GUARD.CONFIRM' | translate }}
              </button>
            }
          </div>
        } @else {
          <div class="tw-flex tw-flex-col tw-gap-1">
            <div class="tw-font-medium tw-text-sm tw-text-gray-300">{{ 'P2P_WIDGETS.SIH_TRADE.STEAM_GUARD.ERROR.BODY1' | translate }}</div>
            <div class="tw-font-normal tw-text-sm tw-text-gray-400">{{ 'P2P_WIDGETS.SIH_TRADE.STEAM_GUARD.ERROR.BODY2' | translate }}</div>
          </div>
        }
      }
    </div>
  } @else {
    <div class="trade-header tw-text-center tw-font-medium tw-text-xl tw-text-gray-100 tw-mb-6">
      {{ 'P2P_WIDGETS.SIH_TRADE.SUCCESS' | translate }}
    </div>
  }
  <app-trade-results-block
    @expansionAnimation
    class="trade-results"
    [tradeOffer]="tradeOffer()"
  ></app-trade-results-block>
  @if (status() === OrderStatusEnum.COMPLETED) {
    <button
      class="close-trade-modal-btn btn btn-primary--design tw-p-3 !tw-no-underline !tw-rounded-xl !tw-flex tw-mt-6 tw-mx-auto tw-min-w-52"
      (click)="closeModal.emit()"
    >
      {{ 'P2P_WIDGETS.SIH_TRADE.OKEY_BUTTON' | translate }}
    </button>
  }
</div>
