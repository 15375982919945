import { StateClass } from 'ngxs-reset-plugin/lib/internals';

import { LanguageState } from '@app/core/language-service';
import { SeoState } from '@app/core/seo-service';
import { AccountState } from '@app/core/state/account';
import { AgreementsState } from '@app/core/state/agreements';
import { ChatState } from '@app/core/state/chat';
import { CurrencyState } from '@app/core/state/currency-store';
import { FaqState } from '@app/core/state/faq';
import { BattleSelectedCasesState, GamesState } from '@app/core/state/games-store';
import { InventoryState } from '@app/core/state/inventory';
import { LayoutState } from '@app/core/state/layout';
import { LotteryState } from '@app/core/state/lottery';
import { ModalsState } from '@app/core/state/modals';
import { NotificationsState } from '@app/core/state/notifications';
import { P2pCommonState, UserStoreState } from '@app/core/state/p2p';
import { PaymentState, RefillState, WithdrawalState } from '@app/core/state/payment-store';
import { ReferralsState } from '@app/core/state/referrals';
import { SkinsState } from '@app/core/state/skins';
import { UserState } from '@app/core/state/user-store';

export const STATES: StateClass<any>[] = [
  ChatState,
  CurrencyState,
  GamesState,
  BattleSelectedCasesState,
  InventoryState,
  LayoutState,
  LotteryState,
  ModalsState,
  NotificationsState,
  PaymentState,
  ReferralsState,
  UserState,
  AccountState,
  LanguageState,
  SeoState,
  SkinsState,
  WithdrawalState,
  RefillState,
  AgreementsState,
  P2pCommonState,
  UserStoreState,
  FaqState,
];
