import { inject, NgModule } from '@angular/core';

import { LazyLoadGameService } from './services';

@NgModule({ providers: [LazyLoadGameService] })
export class PortableGamesModule {
  readonly #lazyLoadGameService = inject(LazyLoadGameService);

  constructor() {
    this.#lazyLoadGameService.registerLazyModals();
  }
}
