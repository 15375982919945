import { AsyncPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterNewModule } from '@dev-fast/ui-components';

import { PromocodeService } from '@app/core/promocode-service';
import { FirstBannerComponent } from '@app/new-market/banners/first-banner/first-banner.component';
import { NewMarketHeaderComponent } from '@app/new-market/new-market-header/new-market-header.component';
import { HEADER_ENGINE } from '@app/new-market/new-market-header/symbols';
import { NotificationComponent } from '@app/notifications';
import { BottomControlPanelModule, REFILL_BANNER_ENGINE, RefillBannerComponent } from '@app/shared/components';
import { ResizedModule } from '@app/shared/directives';
import { ChatModule } from '@app/widgets/chat';
import { WidgetsNotificationsNewModule } from '@app/widgets/notifications-new';
import { PromocodeWidgetModule } from '@app/widgets/promocode';

import { LeftSidebarComponent } from './components/left-sidebar/left-sidebar.component';
import { RightBtnsComponent } from './components/right-btns/right-btns.component';
import { GofastLayoutNewMarketComponent } from './gofast-layout-new-market.component';
import { ChatService } from './services/chat.service';
import { HeaderEngineService } from './services/header-engine.service';
import { MainModalsService } from './services/main-modals.service';
import { NotificationsService } from './services/notifications.service';
import { RefillBannerService } from './services/refill-banner.service';

@NgModule({
  imports: [
    ChatModule.forRoot(ChatService),
    WidgetsNotificationsNewModule.forRoot(NotificationsService),
    PromocodeWidgetModule.forRoot(PromocodeService),
    BottomControlPanelModule,
    LeftSidebarComponent,
    RefillBannerComponent,
    RightBtnsComponent,
    RouterOutlet,
    FooterNewModule,
    NewMarketHeaderComponent,
    AsyncPipe,
    ResizedModule,
    FirstBannerComponent,
    NotificationComponent,
  ],
  providers: [
    MainModalsService,
    {
      provide: REFILL_BANNER_ENGINE,
      useClass: RefillBannerService,
    },
    {
      provide: HEADER_ENGINE,
      useClass: HeaderEngineService,
    },
  ],
  declarations: [GofastLayoutNewMarketComponent],
  exports: [GofastLayoutNewMarketComponent],
})
export class LayoutNewMarketModule {}
