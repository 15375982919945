import { DOCUMENT, ViewportScroller } from '@angular/common';
import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ActivationStart, Router, Scroll } from '@angular/router';
import { combineLatest, filter } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScrollCommonService {
  readonly #document = inject(DOCUMENT);
  readonly #rendererFactory: RendererFactory2 = inject(RendererFactory2);
  readonly #renderer: Renderer2 = this.#rendererFactory.createRenderer(null, null);
  readonly #router: Router = inject(Router);
  readonly #viewportScroller: ViewportScroller = inject(ViewportScroller);

  blockScroll = (): void => {
    this.#renderer.addClass(this.#document.documentElement, 'global-scrollblock');
  };
  unblockScroll = (): void => {
    this.#renderer.removeClass(this.#document.documentElement, 'global-scrollblock');
  };

  disableScrollRestoration(): void {
    this.#viewportScroller.setHistoryScrollRestoration('manual');
  }

  handleScrollOnNavigation(): void {
    // Создаем потоки событий маршрутизации
    const activationStart$ = this.#router.events.pipe(filter((event) => event instanceof ActivationStart));

    const scroll$ = this.#router.events.pipe(filter((event) => event instanceof Scroll));

    // Комбинируем последние значения потоков
    combineLatest([activationStart$, scroll$])
      .pipe(
        tap(([activationStartEvent, scrollEvent]) => {
          const { position, anchor } = scrollEvent as Scroll;
          const noScroll = (activationStartEvent as ActivationStart).snapshot.data['noScroll'];

          // Обрабатываем скроллинг
          if (position) {
            this.#viewportScroller.scrollToPosition(position);
          } else if (anchor) {
            this.#viewportScroller.scrollToAnchor(anchor);
          } else if (!noScroll) {
            this.#viewportScroller.scrollToPosition([0, 0]);
          }
        }),
      )
      .subscribe();
  }
}
