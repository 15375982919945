<div class="search-input">
  <input
    [placeholder]="'P2P_WIDGETS.SEARCH' | translate"
    [formControl]="control"
    (keydown)="onKeyDown($event)"
  />
  <div class="controls tw-flex tw-items-center tw-absolute tw-right-0 tw-h-fit tw-mr-0.5">
    @if (controlHasValue()) {
      <button
        class="tw-flex tw-justify-center tw-items-center tw-text-gray-400 tw-mr-3"
        title="Clear"
        (click)="onClear()"
      >
        <mat-icon svgIcon="clear-new"></mat-icon>
      </button>
    }

    <button
      class="search-btn max-md:tw-hidden"
      [disabled]="!controlHasValue()"
      (click)="onSearch(control.value)"
    >
      {{ 'P2P_WIDGETS.SEARCH_BUTTON' | translate }}
    </button>
  </div>
</div>
