<div
  class="tw-flex tw-flex-col tw-justify-center max-md:tw-items-center tw-p-2 md:tw-px-4 md:tw-py-3 tw-text-sm tw-leading-4 tw-text-gray-400 tw-bg-gray-700 tw-rounded-[5rem] tw-w-full md:tw-w-40"
>
  @if (selectedMethod(); as method) {
    <button
      class="tw-flex tw-gap-1"
      (click)="onToggleSorting()"
    >
      @if (method.icon; as icon) {
        <mat-icon
          class="tw-shrink-0 !tw-w-4 !tw-h-4"
          [class]="icon.src"
          [svgIcon]="icon.src"
        />
      }
      <p class="tw-my-auto max-md:tw-hidden tw-truncate">{{ method.title | translate }}</p>
    </button>
  }
</div>
