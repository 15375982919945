import { BuyViewVariant, IUICheckboxLabel } from '@dev-fast/types';
import { ITabOption } from '@dev-fast/ui-components';

import { ISelectionMenuItem } from '@app/ui/components/lib/form-elements/autocomplete-menu/shared';

export const SORTING_METHODS: [ISelectionMenuItem, ISelectionMenuItem] = [
  {
    title: 'LOCAL.CASES.BATTLE_GAME.FILTERS.FILTER_FIRST_EXPENSIVE',
    name: 'DESC',
    icon: {
      type: 'icon',
      src: 'sort-3-line',
    },
  },
  {
    title: 'LOCAL.CASES.BATTLE_GAME.FILTERS.FILTER_FIRST_CHEAP',
    name: 'ASC',
    icon: {
      type: 'icon',
      src: 'sort-3-line-asc',
    },
  },
];
export const TAB_OPTIONS: ITabOption[] = [
  {
    id: BuyViewVariant.SINGLE,
    labelLocale: 'P2P_WIDGETS.REDESIGN.SELL_MODAL.ONE',
  },
  {
    id: BuyViewVariant.BY_STORE,
    labelLocale: `By store`,
  },
];
export const CHECKBOX_LABELS: IUICheckboxLabel[] = [
  { title: 'P2P_WIDGETS.REDESIGN.BASKET.AGREE_CHECK1' },
  { title: 'P2P_WIDGETS.REDESIGN.BASKET.AGREE_CHECK2', href: '/tos' },
];
