import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SIH_LINK } from '../../model';
import { SihCustomIconComponent } from '../sih-custom-icon/sih-custom-icon.component';

@Component({
  selector: 'app-sih-check-tooltip',
  standalone: true,
  templateUrl: './sih-check-tooltip.component.html',
  styleUrl: './sih-check-tooltip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, NgStyle, SihCustomIconComponent],
})
export class SihCheckTooltipComponent {
  protected readonly sihLink = SIH_LINK;
}
