<div
  class="tw-flex tw-flex-col sm:tw-flex-row md:tw-flex-row tw-px-[1.333rem] tw-pt-[1.333rem] sm:tw-px-6 sm:tw-pt-6 md:tw-pt-12 tw-pb-32 tw-w-full tw-h-full tw-max-w-1800"
  (clickOutside)="closeDropdown()"
>
  <div
    class="tw-flex tw-flex-col tw-gap-2 md:tw-gap-1 tw-text-sm tw-font-medium tw-tracking-wide tw-leading-5 tw-text-gray-200 custom-scroll custom-scroll--hover tw-min-h-11 tw-h-full"
    [class.max-sm:tw-hidden]="activeMenu()"
  >
    @for (item of items(); track item.value; let idx = $index) {
      <button
        class="tw-flex tw-items-center max-sm:tw-gap-[0.67rem] tw-gap-2 tw-p-3 tw-rounded-lg tw-w-full tw-min-w-fit md:tw-min-w-56 hover:tw-text-gray-100 hover:tw-bg-gray-500 tw-min-h-11 tw-bg-gray-700 md:tw-bg-inherit"
        [class.active-category]="idx === activeIndexByMobile()"
        (click)="onMenuItem(idx)"
        tabindex="0"
        role="button"
      >
        <mat-icon
          class="!tw-w-[1.666rem] !tw-h-[1.666rem] sm:!tw-w-5 sm:!tw-h-5 md:!tw-w-6 md:!tw-h-6"
          [svgIcon]="item.icon || ''"
        ></mat-icon>
        <div>{{ item.label | translate }}</div>
      </button>
    }
  </div>

  <div class="tw-p-px tw-mt-0 sm:tw-mx-6 tw-w-full sm:tw-w-px tw-bg-gray-550 tw-h-px sm:tw-h-full max-sm:tw-hidden"></div>
  @if (activeMenu(); as activeMenu) {
    <div class="tw-flex tw-flex-col tw-w-full tw-h-full">
      @if (itemsHasSubItems(activeMenu); as subItems) {
        <ng-container *ngTemplateOutlet="backButtonTemplate; context: { activeMenu }"></ng-container>

        <div class="sub-items max-sm:tw-mt-8 tw-overflow-auto custom-scroll tw-scroll- custom-scroll--hover">
          @for (subItem of subItems.items; track subItem.value) {
            <div class="sub-item tw-flex tw-flex-col tw-text-sm tw-leading-5 tw-text-gray-400 tw-min-w-36">
              <span class="tw-text-xl tw-font-semibold tw-mb-4 tw-tracking-normal tw-leading-6 tw-text-white">
                {{ subItem.label | translate }}
              </span>
              @for (subSubItem of subItem.items; track subSubItem.value) {
                <button
                  class="tw-flex max-md:tw-p-[2px] tw-gap-1.5 first-of-type:tw-mt-0 tw-mt-[0.33rem] sm:tw-mt-1 tw-whitespace-nowrap tw-text-gray-300 hover:tw-text-gray-100 max-sm:tw-bg-gray-600 max-sm:tw-rounded-[0.666rem] tw-rounded-lg"
                  (click)="onItem(subItem, subSubItem)"
                >
                  <img
                    loading="lazy"
                    [src]="'assets/img/design/market/skins/' + subSubItem.icon + '.webp'"
                    class="tw-shrink-0 tw-w-14 tw-aspect-[1.33] max-sm:tw-w-[4.66rem]"
                    [alt]="subSubItem.value"
                  />
                  <span class="tw-my-auto max-sm:tw-text-[1.166rem] max-sm:tw-leading-[1.4]">{{ subSubItem.label | translate }}</span>
                </button>
              }
            </div>
          }
        </div>
      } @else {
        <ng-container *ngTemplateOutlet="backButtonTemplate; context: { activeMenu }"></ng-container>

        <div
          class="no-subitems-grid tw-w-full tw-h-full tw-gap-[0.33rem] sm:tw-gap-4 max-sm:tw-mt-[1.333rem] tw-overflow-auto custom-scroll custom-scroll--hover"
        >
          @for (subItem of activeMenu.items; track subItem.value) {
            <button
              class="no-subitems-card tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between tw-items-center tw-p-3 tw-rounded-lg tw-bg-gray-600 tw-text-gray-300 hover:tw-bg-gray-550"
              (click)="onItem(activeMenu, subItem)"
            >
              <img
                loading="lazy"
                [src]="'assets/img/design/market/skins/' + subItem.icon + '.webp'"
                [alt]="subItem.value"
              />
              <div>{{ subItem.label | translate }}</div>
            </button>
          }
        </div>
      }
    </div>
  }
</div>
<ng-template
  #backButtonTemplate
  let-activeMenu="activeMenu"
>
  <button
    class="tw-flex tw-items-center tw-text-gray-200 tw-text-lg sm:tw-hidden"
    (click)="onBack()"
  >
    <mat-icon
      svgIcon="chevron-right"
      class="tw-rotate-180"
    ></mat-icon>
    {{ activeMenu.label | translate }}
  </button>
</ng-template>
