import { animate, group, keyframes, query, style, transition, trigger } from '@angular/animations';

export const sidebarAnimation = trigger('sidebarAnimation', [
  transition('false <=> true', [
    group([
      // query('.sidebar__btns', []),
      query(
        '.sidebar__menu-btn',
        [
          animate(
            '500ms linear',
            keyframes([
              style({ transform: 'rotate(180deg)' }),
              style({ transform: 'rotate(90deg)' }),
              style({ transform: 'rotate(40deg)' }),
              style({ transform: 'rotate(10deg)' }),
            ])
          ),
        ],
        { optional: true }
      ),
    ]),
  ]),
]);
