<div class="tw-flex tw-w-full tw-h-full max-md:tw-flex-col max-md:tw-h-full">
  <!-- left-->
  <div
    class="tw-flex tw-flex-col tw-gap-6 tw-bg-gray-650 tw-py-6 tw-px-4 md:tw-p-8 md:tw-rounded-l-3xl max-md:tw-h-full tw-min-w-[64%] max-md:tw-rounded-t-3xl max-md:tw-w-full"
  >
    <h2>{{ 'P2P_WIDGETS.REDESIGN.BASKET.MODAL_NAME' | translate }}</h2>
    <div class="tw-flex tw-justify-between">
      <app-simple-sorting
        [items]="sortingMethods()"
        (methodChanged)="onSortingTypeChange($event)"
      />
      <!--      <div class="tw-flex">-->
      <!--        <app-ui-tabs-->
      <!--          class="button-tabs tw-w-fit"-->
      <!--          [(selectedId)]="selectedId"-->
      <!--        >-->
      <!--          @for (option of tabOptions(); track option.id) {-->
      <!--            <app-ui-tab [id]="option.id">{{ option.labelLocale | translate }}</app-ui-tab>-->
      <!--          }-->
      <!--        </app-ui-tabs>-->
      <!--      </div>-->
      <div class="tw-flex tw-gap-4 tw-items-center">
        <div
          class="tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-bg-gray-700 tw-rounded-full max-sm:tw-w-12 max-sm:tw-h-12 sm:max-md:tw-w-9 sm:max-md:tw-h-9 tw-w-11 tw-h-11"
          (click)="onRemoveAll()"
        >
          <mat-icon
            class="tw-text-gray-400 !tw-w-5 !tw-h-5"
            svgIcon="market--delete_exchange"
          />
        </div>
      </div>
    </div>
    <ng-scrollbar class="ng-scrollbar">
      <div class="tw-flex tw-flex-col">
        @for (purchaseItem of selectedPurchaseItems(); track purchaseItem.id) {
          <app-basket-item-card
            class="tw-mb-[0.88rem]"
            [class.kit]="isKit(purchaseItem)"
            [purchaseItem]="purchaseItem"
            [nativeBreakpoint]="nativeBreakpoint()"
            (remove)="removeSelectedItem(purchaseItem)"
          />
        }
      </div>
    </ng-scrollbar>

    <button
      (click)="onBuy()"
      class="tw-flex tw-items-center tw-gap-2 tw-justify-center tw-px-8 tw-py-4 tw-mt-5 tw-text-base tw-font-medium tw-bg-yellow-500 tw-rounded-xl tw-text-gray-800 md:tw-hidden"
    >
      <span class="tw-tracking-wide tw-leading-[150%]">{{ 'Buy for' | translate }}</span>
      <app-currency [value]="summaryPrice()" />
    </button>
    <app-ui-checkbox
      class="md:tw-hidden"
      [formControl]="agreement"
      [labels]="checkboxLabels"
      [checkValidation$]="checkValidation$"
    />
  </div>

  <!-- right-->
  <div
    class="tw-flex tw-flex-col md:tw-rounded-r-3xl tw-justify-between tw-p-8 tw-bg-gray-700 md:tw-max-w-[378px] tw-min-w-[36%] tw-gap-[1.44rem] max-md:tw-hidden"
  >
    <div
      class="tw-rounded-full tw-cursor-pointer tw-flex tw-self-end"
      (click)="onClose()"
    >
      <mat-icon
        svgIcon="close"
        class="tw-text-gray-400 !tw-w-4 !tw-h-4"
      ></mat-icon>
    </div>
    <ng-scrollbar class="ng-scrollbar ng-scrollbar-right">
      <div class="tw-flex tw-flex-col tw-justify-between tw-h-full">
        <div class="tw-flex tw-flex-col tw-justify-start">
          <app-new-warning-banner
            class="by-row tw-mb-6"
            [text]="'P2P_WIDGETS.WARN.DELIBERATELY_BREAKDOWN_OFFER'"
          />
          <!-- prices -->
          <div class="tw-flex tw-flex-col tw-gap-3">
            @if (profit(); as profit) {
              <div class="tw-flex tw-gap-5 tw-justify-between tw-w-full tw-font-medium">
                <div class="tw-my-auto tw-text-sm tw-text-gray-150">{{ 'Profit' | translate }}</div>
                <div class="tw-flex tw-items-center tw-gap-1.5 tw-text-lg tw-text-green-500">
                  <div class="tw-leading-[122%]">+</div>
                  <app-currency [value]="profit" />
                </div>
              </div>
            }

            <div class="tw-flex tw-gap-5 tw-justify-between tw-w-full tw-font-medium">
              <div class="tw-my-auto tw-text-sm tw-text-gray-150">{{ countSelectedItems() }} {{ 'items' | translate }}</div>
              <app-currency
                class="summary-price"
                [value]="summaryPrice()"
              />
            </div>
          </div>
          @if (canProceedWithTrade()) {
            <button
              (click)="onBuy()"
              class="tw-flex tw-items-center tw-gap-2 tw-justify-center tw-px-8 tw-py-4 tw-my-[1.19rem] tw-text-base tw-font-medium tw-bg-yellow-500 tw-rounded-xl tw-text-gray-800"
            >
              <span class="tw-tracking-wide tw-leading-[150%]">{{ 'Buy for' | translate }}</span>
              <app-currency [value]="summaryPrice()" />
            </button>
          } @else {
            <button
              class="tw-flex tw-items-center tw-gap-2 tw-justify-center tw-px-8 tw-py-4 tw-my-[1.19rem] tw-text-base tw-font-medium tw-bg-yellow-500 tw-rounded-xl tw-text-gray-800"
              (click)="handleErrorAction(permissions())"
            >
              {{ permissionErrorBtnText() | translate: { days: permissions()?.canTrade?.days || 0 } | uppercase }}
            </button>
          }
          <app-ui-checkbox
            [formControl]="agreement"
            [labels]="checkboxLabels"
            [checkValidation$]="checkValidation$"
            [checkboxClasses]="'basket-agreement'"
          />
        </div>

        <app-sih-redirect-block class="basket__sih-redirect-block" />
      </div>
    </ng-scrollbar>
  </div>
</div>
