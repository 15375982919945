import { Injectable } from '@angular/core';
import { ActiveRoute, Design, FooterCounts, MarketCounts, ModalNames, NewMenu, NewPanel } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { AuthState } from '@app/auth';
import { LanguageService } from '@app/core/language-service';
import { NotificationsService } from '@app/core/notification-service';
import { GetLevelsRoadMap } from '@app/core/state/account';
import { ChangeDesign, ClickOnLockedSide, ClosePanel, LayoutState, OpenPanel } from '@app/core/state/layout';
import { GetLotteryItems } from '@app/core/state/lottery';
import { OpenModal } from '@app/core/state/modals';
import { CommonStoreService } from '@app/core-state-common';

@Injectable({
  providedIn: 'root',
})
export class GofastMarketLayoutService {
  @Select(LayoutState.activePanelNew)
  readonly panels$!: Observable<NewPanel[]>;
  @Select(LayoutState.activeMenu)
  readonly menu$!: Observable<NewMenu[]>;
  @Select(LayoutState.isFadeSides)
  readonly isFadeSides$!: Observable<boolean>;
  @Select(LayoutState.isNewDesign)
  readonly isNewDesign$!: Observable<boolean>;
  @Select(LayoutState.gameSelectorOpened)
  readonly gameSelectorOpened$!: Observable<boolean>;
  @Select(LayoutState.counts)
  readonly counts$!: Observable<FooterCounts>;
  @Select(LayoutState.marketCounts)
  readonly marketCounts$!: Observable<MarketCounts>;
  @Select(LayoutState.online)
  readonly online$!: Observable<number>;
  @Select(AuthState.isAuth)
  readonly isAuth$!: Observable<boolean>;
  @Select(LayoutState.activeRoute)
  readonly activeRoute$!: Observable<ActiveRoute | null>;

  init(): void {
    this.commonService.initUserState();
    this.commonService.getCurrencySettings();
    this.languageService.getLocales();
    this.notificationsService.getNotifications();
  }

  constructor(
    private commonService: CommonStoreService,
    private readonly notificationsService: NotificationsService,
    private readonly languageService: LanguageService,
  ) {}

  @Dispatch() clickOnLockedSide = (): ClickOnLockedSide => new ClickOnLockedSide();
  @Dispatch() getLotteryItems = (): GetLotteryItems => new GetLotteryItems();
  @Dispatch() getLevelsRoadMap = (): GetLevelsRoadMap => new GetLevelsRoadMap();
  @Dispatch() openModal = (name: ModalNames, payload?: any): OpenModal => new OpenModal(name, payload);
  @Dispatch() changeDesign = (value: Design): ChangeDesign => new ChangeDesign(value);
  @Dispatch() openPanel = (panel: NewPanel): OpenPanel => new OpenPanel(panel);
  @Dispatch() closePanel = (panel: NewPanel): ClosePanel => new ClosePanel(panel);
}
