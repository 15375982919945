import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, model, ModelSignal, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

import { sidebarAnimation } from '../../animations/sidebar.animation';

@Component({
  selector: 'app-right-btns',
  templateUrl: './right-btns.component.html',
  styleUrls: ['./right-btns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [sidebarAnimation],
  imports: [MatIcon, NgClass],
})
export class RightBtnsComponent {
  @Input() hiddenScrollBtn!: boolean;
  @Input() disableTradeBtn?: boolean;
  isMobileMenuOpened: ModelSignal<boolean> = model(false);
  @Input() isOpenedRightPanel!: boolean;

  @Output() clickScrollTop = new EventEmitter<void>();
  @Output() clickTradeBtn = new EventEmitter<void>();
  @Output() clickChatra = new EventEmitter<void>();
  @Output() toggleMobileMenu = new EventEmitter<void>();

  onClickScrollTop(): void {
    this.clickScrollTop.emit();
  }
  onClickTradeBtn(): void {
    this.clickTradeBtn.emit();
  }
  onClickOpenChatra(): void {
    this.clickChatra.emit();
  }
  onToggleMobileMenu(): void {
    this.isMobileMenuOpened.set(!this.isMobileMenuOpened());
  }
}
