<div class="md:tw-p-8 tw-relative">
  <div
    class="tw-absolute tw-top-[2%] tw-right-[1%] tw-text-gray-400 hover:tw-text-gray-300 tw-cursor-pointer"
    (click)="closeModal.emit()"
  >
    <mat-icon svgIcon="close"></mat-icon>
  </div>
  @if (isMobileView(); as isMobileView) {
    @if (tradeOffer()?.status; as status) {
      @if (isFirstStep() || isSihDisabled()) {
        <app-sih-check-panel
          [isMobileView]="isMobileView"
          (goBack)="createTradeManually.emit()"
        ></app-sih-check-panel>
      } @else if (!orderSuccessful.includes(status)) {
        <app-creating-trade-panel
          [status]="status"
          (closeModal)="closeModal.emit()"
          (solveProblem)="closeModal.emit()"
          (createTradeManually)="closeModal.emit()"
        ></app-creating-trade-panel>
      } @else {
        <app-proceed-with-trade
          [status]="status"
          [tradeOffer]="tradeOffer()"
          [isMobileView]="isMobileView"
          (closeModal)="closeModal.emit()"
        ></app-proceed-with-trade>
      }
    }
  } @else {
    @if (tradeOffer()?.status; as status) {
      @if (isFirstStep()) {
        <div @expansionAnimation>
          <app-sih-check-panel></app-sih-check-panel>
          <div class="tw-bg-gray-550 tw-h-px tw-my-6"></div>
          <div class="tw-flex tw-gap-6 tw-items-stretch tw-w-full">
            <div class="tw-flex tw-flex-col tw-w-3/4">
              <div class="tw-bg-gray-550 tw-h-6 tw-w-60 tw-mb-3"></div>
              <div class="tw-bg-gray-550 tw-h-20 tw-w-full tw-mb-12"></div>
              <div class="tw-flex tw-gap-4">
                <div class="tw-bg-gray-550 tw-h-11 tw-w-40"></div>
                <div class="tw-bg-gray-550 tw-h-11 tw-w-40"></div>
              </div>
            </div>
            <div class="tw-bg-gray-550 tw-w-72"></div>
          </div>
        </div>
      } @else if (isSihDisabled()) {
        <app-sih-confirm-panel @expansionAnimation></app-sih-confirm-panel>
      } @else if (!orderSuccessful.includes(status)) {
        <app-creating-trade-panel
          [status]="status"
          (closeModal)="closeModal.emit()"
          (solveProblem)="closeModal.emit()"
          (createTradeManually)="createTradeManually.emit()"
        ></app-creating-trade-panel>
      } @else {
        <app-proceed-with-trade
          [status]="status"
          [tradeOffer]="tradeOffer()"
          (closeModal)="closeModal.emit()"
        ></app-proceed-with-trade>
      }
    }
  }
</div>
