import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MenuItem } from '@dev-fast/types';

import { LangRouterModule } from '@app/shared/directives';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, LangRouterModule],
})
export class LeftSidebarComponent {
  readonly items: InputSignal<MenuItem[] | undefined> = input();
}
