import { SellVariant } from '@dev-fast/types';
import { ITabOption } from '@dev-fast/ui-components';

import { ISelectionMenuItem } from '@app/ui/components/lib/form-elements/autocomplete-menu/shared';

export const SORTING_METHODS: [ISelectionMenuItem, ISelectionMenuItem] = [
  {
    title: 'LOCAL.CASES.BATTLE_GAME.FILTERS.FILTER_FIRST_EXPENSIVE',
    name: 'DESC',
    icon: {
      type: 'icon',
      src: 'sort-3-line',
    },
  },
  {
    title: 'LOCAL.CASES.BATTLE_GAME.FILTERS.FILTER_FIRST_CHEAP',
    name: 'ASC',
    icon: {
      type: 'icon',
      src: 'sort-3-line-asc',
    },
  },
];
export const TAB_OPTIONS: ITabOption[] = [
  {
    id: SellVariant.SINGLE,
    labelLocale: 'P2P_WIDGETS.REDESIGN.SELL_MODAL.ONE',
  },
  {
    id: SellVariant.KIT,
    labelLocale: `P2P_WIDGETS.REDESIGN.SELL_MODAL.PACK`,
  },
];
