<div class="tw-flex tw-flex-col">
  <div
    class="tw-flex tw-gap-3 tw-items-center tw-justify-between"
    [class.!tw-items-start]="kitCount()"
  >
    <div class="tw-flex tw-flex-col tw-w-full">
      @if (kitCount(); as count) {
        <span class="tw-mb-4">
          {{ 'Kit from' + count + ' items' }}
        </span>
      }
      @for (kit of lotInfoArr(); track kit; let index = $index, last = $last, count = $count) {
        <div class="content-block tw-gap-3 tw-w-full">
          <app-lot-card-item-info [items]="kit">
            @if (nativeBreakpoint() === 'xxs') {
              <ng-container *ngTemplateOutlet="priceTemplate; context: { kit: kit }"></ng-container>
            }
          </app-lot-card-item-info>
          @if (nativeBreakpoint() !== 'xxs') {
            <ng-container *ngTemplateOutlet="priceTemplate; context: { kit: kit }"></ng-container>
          }
        </div>
        @if (!last && count > 1) {
          <!-- кругляш разделитель между скинами если кит-->
          <div class="tw-rounded-full tw-bg-gray-500 tw-w-2 tw-aspect-square tw-my-[3px] tw-ml-[3.25rem]"></div>
        }
      }
    </div>
    <button (click)="onRemove()">
      <mat-icon
        svgIcon="close"
        class="tw-text-gray-550 !tw-w-6 !tw-h-6"
        [class.tw-self-start]="kitCount()"
      ></mat-icon>
    </button>
  </div>
</div>
<ng-template
  #priceTemplate
  let-kit="kit"
>
  @if (getPriceInfo(kit); as priceInfo) {
    <app-lot-card-price-info
      class="tw-min-w-40 tw-self-center"
      [price]="priceInfo.price"
      [overprice]="priceInfo.overprice"
      [recommendedPrice]="priceInfo.recommendedPrice"
    />
  }
</ng-template>
